import { usePage, router } from "@inertiajs/vue3"
import { computed } from "vue"
import route from "ziggy-js"

export default function useNavRouting(props) {
	const navItemClicked = () => {
		const navItem = props.item
		if (navItem.children) return

		// if navItem.route is a function, call it, otherwise use navItem.route
		const url =
			typeof navItem.route === "function"
				? navItem.route({ props, page: usePage() })
				: navItem.route

		router.visit(url, {
			method: navItem.method ? navItem.method : "get",
			data: navItem.data ? navItem.data : {},
			preserveState: true,
			preserveScroll: true,
		})
	}

	const href = computed(() => {
		return hrefComputed(props.item)
	})

	const hrefComputed = (navItem) => {
		try {
			if (navItem.method && navItem.method.toLowerCase() != "get") {
				return "#"
			}
			if (navItem.tab) return "#"
			return (
				navItem.href ||
				(typeof navItem.route === "function"
					? navItem.route({ props, page: usePage() })
					: navItem.route)
			)
		} catch (e) {
			console.log(e)
			return "#"
		}
	}

	const handleItemClicked = (navItem, event) => {
		const url =
			typeof navItem.route === "function"
				? navItem.route({ props, page: usePage() })
				: navItem.route
		if (navItem.method && navItem.method != "get" && url !== "#") {
			event.preventDefault()
			event.stopPropagation()
			if (navItem.tab) {
				window.open(url, "_blank")
			} else {
				router.visit(url, {
					method: navItem.method ? navItem.method : "get",
					data: navItem.data ? navItem.data : {},
					preserveState:
						navItem.preserveState !== undefined ? navItem.preserveState : true,
					preserveScroll:
						navItem.preserveScroll !== undefined
							? navItem.preserveScroll
							: true,
				})
			}
		}
	}

	return {
		navItemClicked,
		href,
		handleItemClicked,
		hrefComputed,
	}
}
