<script setup>
import { useRegisterSW } from "@/Utils/registerSW"
import { ref } from "vue"
const isOnline = ref(navigator.onLine)
const showOffline = ref(false)
const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
	filename: "sw.js",
	immediate: true,
})

const close = async () => {
	offlineReady.value = false
	needRefresh.value = false
	showOffline.value = false
}
window.addEventListener("load", () => {
	window.addEventListener("online", () => {
		showOffline.value = false
		isOnline.value = true
	})

	window.addEventListener("offline", () => {
		showOffline.value = true
		isOnline.value = false
	})
})
</script>

<template>
	<div v-if="needRefresh" class="pwa-toast" role="alert">
		<div class="message">New content available</div>
		<button class="btn btn-primary rounded-pill" @click="updateServiceWorker()">
			Refresh
		</button>
		<button class="btn btn-outline-primary rounded-pill" @click="close">
			Dismiss
		</button>
	</div>
	<div
		v-if="!isOnline && showOffline"
		class="pwa-toast pwa-offline"
		role="alert"
	>
		<div class="message">You're currently offline.</div>
		<button class="btn btn-outline-primary rounded-pill" @click="close">
			Dismiss
		</button>
	</div>
</template>

<style>
.pwa-toast {
	position: fixed;
	right: 0;
	bottom: 0;
	margin: 16px;
	padding: 12px;
	border: 1px solid #0008db;
	border-radius: 4px;
	text-align: right;
	box-shadow: 3px 4px 5px 0 #8885;
	background-color: white;
	z-index: 1000;
}
.pwa-offline {
	z-index: 1001;
}
.pwa-toast .message {
	margin-bottom: 8px;
	width: 300px;
	text-align: left;
}
.pwa-toast button {
	border: 1px solid #8885;
	outline: none;
	margin-right: 5px;
	border-radius: 2px;
	padding: 3px 10px;
}
</style>
