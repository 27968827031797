<template>
	<MDBDropdown v-model="menu" class="h-100">
		<MDBDropdownToggle
			tag="a"
			:class="[
				'd-flex align-items-center link-body-emphasis text-white text-decoration-none rounded-pill border border-primary p-0 h-100',
				{
					'bg-primary-3': menu,
					'btn-outline-primary': !menu,
				},
			]"
			:ripple="{ radius: 0 }"
			@click.prevent="((menu = !menu), (tooltip = !tooltip))"
		>
			<div class="d-flex align-items-center p-1">
				<div
					v-if="
						currentTeam.logo_file_name_url &&
						currentTeam.logo_file_name_url.length > 0
					"
					class="d-inline-block rounded-circle bg-primary"
				>
					<img :src="computedLogo" class="rounded-circle" />
				</div>
				<div
					v-else
					class="initials small text-uppercase d-inline-flex justify-content-center align-items-center p-1 rounded-circle border border-secondary-2 bg-primary text-white"
				>
					{{ getInitials(currentTeam.name) }}
				</div>
				<div class="d-md-inline-block d-none team-name small ms-1">
					{{ currentTeam.name }}
				</div>
				<FontAwesomeIcon
					:icon="lookupIcon('caret-down', 'fas')"
					:class="['px-md-3 px-2']"
					size="xs"
				/>
			</div>
		</MDBDropdownToggle>
		<MDBDropdownMenu aria-labelledby="dropdownMenuButton" class="mt-2">
			<template v-if="teamsCount > 1">
				<div class="subtitle text-primary">Switch Organizations</div>
				<BaseNavSubItem
					v-for="(item, index) in recentTeamsList"
					:key="index"
					:item="item"
					class="text-black"
					@close-menu="menu = false"
				/>
				<BaseNavSubItem
					:item="items[0]"
					class="text-black"
					@close-menu="menu = false"
				/>
			</template>
			<div class="subtitle text-primary">Organization Settings</div>
			<BaseNavSubItem
				v-for="(item, index) in items.slice(1)"
				:key="index"
				:item="item"
				class="text-black"
				@close-menu="menu = false"
			/>
			<div class="subtitle text-primary">User Settings</div>

			<BaseNavSubItem
				v-for="(item, index) in userItems"
				:key="index"
				:item="item"
				class="d-block"
				@close-menu="menu = false"
			>
			</BaseNavSubItem>
		</MDBDropdownMenu>
	</MDBDropdown>
</template>

<script setup>
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu } from "mdb-vue-ui-kit"
import { Link } from "@inertiajs/vue3"
import { ref, computed, watch, onMounted } from "vue"
import BaseNavSubItem from "./BaseNavSubItem.vue"
import { usePreferenceStore } from "@/Store/adminPreferenceStore"
import { useCurrentTeamStore } from "../Store/currentTeamStore"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import route from "ziggy-js"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const currentTeamStore = useCurrentTeamStore()
const preferenceStore = usePreferenceStore()

const currentTeam = computed(() => currentTeamStore.getCurrentTeam)
const tooltip = ref(false)
const menu = ref(false)
const recentTeamsList = ref([])
const useLogoThumbnail = ref(true)

const setRecentTeamsList = () => {
	let recentTeams = currentTeamStore.getRecentTeams.filter(
		(team) => team?.id !== currentTeam.value?.id,
	)

	return recentTeams.map((team) => {
		return {
			route: route("dashboard.current-team.update"),
			method: "put",
			data: {
				team_id: team.id,
			},
			title: team.name,
			logo:
				team.logo_file_name_thumbnail_url ??
				team.logo_file_name_url ??
				"team-initial",
			preserveState: false,
		}
	})
}
watch(currentTeam, () => {
	recentTeamsList.value = setRecentTeamsList()
})
onMounted(() => {
	recentTeamsList.value = setRecentTeamsList()
})

const getInitials = (name) => {
	if (!name) {
		return ""
	}
	const nameArr = name.split(" ")
	if (nameArr.length > 1) {
		return nameArr[0][0] + nameArr[1][0]
	}
	return nameArr[0][0]
}

const computedLogo = computed(() => {
	return (
		currentTeam.value.logo_file_name_thumbnail_url ??
		currentTeam.value.logo_file_name_url
	)
})

const toggleTheme = () => {
	document.body.classList.toggle("dark")
	if (document.body.classList.contains("dark")) {
		preferenceStore.setPreference("theme", "dark")
	} else {
		preferenceStore.setPreference("theme", "light")
	}
}
const theme = computed(() => {
	return preferenceStore.getPreference["theme"]
})

const props = defineProps({
	items: {
		type: Object,
		default: () => ({
			href: undefined,
			icon: undefined,
			title: undefined,
			to: undefined,
		}),
	},
	userItems: {
		type: Object,
		default: () => ({
			href: undefined,
			icon: undefined,
			title: undefined,
			to: undefined,
		}),
	},
	teamsCount: {
		type: Number,
		default: 1,
	},
})
</script>
<style scoped>
.dropdown-toggle::after {
	display: none;
}
.dropdown-menu > *:nth-child(n + 2) {
	border: none;
	border-top: 1px solid var(--mdb-border-color);
}
.dropdown-menu .subtitle {
	font-size: 12px;
	text-align: center;
	padding: 7.5px;
}
.recent-team {
	background-color: transparent;
}
.recent-team.selected,
.recent-team:hover {
	background-color: #d5d6ff;
}
.recent-team i {
	visibility: hidden;
}

.recent-team.selected i::before {
	background-color: var(--mdb-primary);
	color: white;
	visibility: visible;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
img {
	width: 30px;
	height: 30px;
}
/* Initials to be removed when teams can upload logos */
.initials {
	width: 30px;
	height: 30px;
	font-size: 0.625rem;
}
:deep(.ripple-wave) {
	display: none;
}
@media (max-width: 767px) {
}
</style>
