<template>
	<!-- Navbar-->
	<div
		class="header border-bottom border-secondary-3 position-relative mb-5 mb-md-0"
	>
		<!-- Toggler -->
		<div class="d-flex flex-nowrap align-items-center py-3 m-0 fw-bold gap-2">
			<div class="left-header d-flex align-items-center">
				<IconBadge
					icon="bars"
					class="text-black mobile-menu border rounded-circle p-2"
					@click="toggleExpand"
				/>
				<img src="/images/logo_dark.png" class="mobile-logo" />
				<div
					v-if="
						currentAdmin &&
						activeProject == null &&
						(!pageTitle || pageTitle.length == 0)
					"
					class="text-black mb-0 fw-bold breadcrumb-text d-flex align-items-center"
				>
					Welcome, {{ currentAdmin.name }}!
				</div>
				<div
					v-if="
						projectName ||
						(pageTitle && pageTitle.length) ||
						(topBreadcrumb !== null && Object.keys(topBreadcrumb).length)
					"
					class="breadcrumb-text d-flex align-items-center mb-0"
				>
					<div
						class="d-flex align-items-center w-100 white-space-nowrap overflow-hidden text-overflow-ellipsis"
					>
						<template
							v-if="
								topBreadcrumb !== null && Object.keys(topBreadcrumb).length > 0
							"
						>
							<Link
								class="fw-bold text-primary-6 d-flex"
								:href="topBreadcrumb.urlString"
							>
								<FontAwesomeIcon
									v-if="topBreadcrumb.icon"
									:icon="lookupIcon(topBreadcrumb.icon, 'fas')"
									class="fa-fw me-1"
									size="lg"
								/>
								<span
									v-if="topBreadcrumb.label && topBreadcrumb.label.length > 0"
									>{{ topBreadcrumb.label || "" }}</span
								>
							</Link>
							<IconBadge
								v-if="topBreadcrumb.label && topBreadcrumb.label.length > 0"
								icon="chevron-right"
								size="xs"
								:options="{
									classList: ['mx-2', 'text-secondary-7', 'chevron'],
								}"
							/>
						</template>
						<div
							class="d-flex align-items-center w-100 white-space-nowrap overflow-hidden text-overflow-ellipsis"
						>
							<div
								v-if="projectName && activeProject"
								class="d-flex flex-row align-items-center"
							>
								<Link
									:href="`/projects/${activeProject.slug}/dashboard`"
									class="text-secondary-7"
								>
									{{ projectName }}
								</Link>
							</div>
							<template v-if="breadCrumbs?.length">
								<div
									v-for="(breadCrumb, index) in breadCrumbs"
									:key="breadCrumb.url"
									:class="[
										'overflow-hidden',
										'white-space-nowrap',
										{
											'flex-shrink-0': index < breadCrumbs.length - 1,
											'text-overflow-ellipsis':
												index === breadCrumbs.length - 1,
										},
									]"
								>
									<IconBadge
										v-if="projectName || index > 0"
										icon="chevron-right"
										size="xs"
										:options="{
											classList: ['mx-2', 'text-secondary-7', 'chevron'],
										}"
									/>
									<Link
										:href="breadCrumb.url"
										:class="{
											'text-secondary-7': index < breadCrumbs.length - 1,
											'text-black': index === breadCrumbs.length - 1,
										}"
									>
										{{ $t(breadCrumb.title) }}
									</Link>
								</div>
							</template>
							<template v-else-if="pageTitle && pageTitle.length">
								<IconBadge
									v-if="projectName"
									icon="chevron-right"
									size="xs"
									:options="{
										classList: ['mx-2', 'text-secondary-7', 'chevron'],
									}" />
								<span id="page-title">
									{{ $t(pageTitle) }}
								</span>
								<span
									v-if="pageTitleAppend"
									id="page-title-append"
									class="px-3 lh-1 small"
									:class="pageTitleAppend.classList || []"
									tabindex="0"
									role="button"
									@click="pageTitleAppendClicked"
								>
									<span class="fw-bold">{{ pageTitleAppend.label }}</span>
									<FontAwesomeIcon
										v-if="pageTitleAppend.icon"
										:icon="pageTitleAppend.icon"
										size="sm"
									/> </span
							></template>
							<div
								v-if="
									pageTitle &&
									pageTitle.length &&
									activeProject !== null &&
									bookmark &&
									bookmark.urlString &&
									userHasFeature('bookmarks')
								"
								class="d-flex align-items-center"
							>
								<ShortcutToggle
									:label="bookmark.label"
									:icon="bookmark.icon"
									:url-string="bookmark.urlString"
									class="ms-2"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<MDBTooltip v-model="presenceTooltip" direction="bottom">
				<template #reference>
					<a
						v-if="userList.length"
						:href="
							isSuperAdmin && userList.length === 1
								? `/admins/${userList[0].id}`
								: '#'
						"
						class="rounded-circle bg-primary-3 text-white p-2 text-center header-icon activeUser ms-2"
						data-mdb-tooltip-init
					>
						{{
							userList.length === 1
								? getInitials(userList[0].name)
								: userList.length
						}}
					</a>
				</template>
				<template #tip>
					<template v-for="(presentUser, index) in userList">
						<br v-if="index !== 0" :key="presentUser.id" />
						{{ presentUser.name }}
					</template>
				</template>
			</MDBTooltip>
			<div class="ms-auto d-flex justify-content-end gap-2">
				<div class="layout-header d-flex align-items-center gap-2">
					<NotificationsModal />
					<TeamNav
						:items="organizationItems"
						:user-items="userItems"
						:teams-count="props.teamsCount"
					/>
				</div>
			</div>
		</div>
	</div>
	<div
		v-if="isSystemRoute"
		class="nav nav-tabs bg-warning py-1 rounded-0 text-dark small d-flex align-items-center"
	>
		<FontAwesomeIcon :icon="lookupIcon('user-secret', 'fas')" class="me-2" />
		You are currently viewing the Platform Admin Portal
	</div>
	<div
		v-if="props.activeProject && props.activeProject.preview_mode"
		class="nav nav-tabs alert alert-danger py-1 rounded-0 text-white small"
	>
		For demonstration purposes only - visit
		<Link
			:href="
				route('dashboard.subscriptions.index', {
					team: usePage().props.currentTeam?.slug,
				})
			"
			class="px-1 text-decoration-underline text-white"
			>Subscriptions</Link
		>
		to learn more
	</div>
	<ul
		v-if="itemsHeader.length > 0"
		class="nav nav-tabs nav-tabs-header sticky-top flex-column flex-sm-row gap-2 py-2 border-bottom border-secondary-3 bg-secondary-2"
		:class="{ 'mb-2': showTabs.length === 0 }"
	>
		<li v-for="(nav, i) in itemsHeader" :key="i" class="nav-item">
			<Link
				:rel="
					hrefComputed(nav) && hrefComputed(nav) !== '#'
						? 'noopener'
						: undefined
				"
				:href="hrefComputed(nav) || '#'"
				:class="[
					'nav-item my-1 my-md-0 border fw-bold text-black nav-link bg-white',
					{
						active:
							nav.component == activeComponent ||
							(nav.activeComponents &&
								nav.activeComponents.includes(activeComponent)),
					},
				]"
				@click="handleItemClicked(nav, $event)"
			>
				<FontAwesomeIcon
					v-if="nav.icon"
					:icon="lookupIcon(nav.icon, 'fas')"
					class="fa-fw me-2"
				/>
				{{ $t(nav.title) }}
			</Link>
		</li>
	</ul>
	<ul
		v-if="showTabs.length > 1 || getAppendDetails?.href"
		class="nav nav-tabs nav-tabs-header sticky-top gap-2 py-2 border-bottom mb-2 border-secondary-3 bg-secondary-2 flex-nowrap flex-sm-wrap white-space-nowrap align-items-center"
	>
		<FormField
			v-model="currentTab"
			:field="{
				type: 'select',
				options: showTabsComputed,
				columnClass: [
					'showTabs',
					'w-auto',
					'flex-grow-1',
					'd-flex',
					'align-items-center',
					'd-md-none',
				],
				classList: ['small'],
			}"
		/>
		<MDBTabs
			v-if="showTabs.length > 1 && showMainMenu == false"
			v-model="currentTab"
			@update:model-value="changeTab"
		>
			<MDBTabItem
				v-for="(nav, i) in showTabs"
				:key="i"
				:tab-id="nav.tabId"
				:href="nav.tabId"
				class="nav-item border fw-bold text-black bg-white border-secondary-3 small px-3"
			>
				<FontAwesomeIcon
					v-if="nav.icon"
					:icon="lookupIcon(nav.icon, 'fas')"
					class="fa-fw me-2"
				/>
				{{ $t(nav.title) }}
			</MDBTabItem>
		</MDBTabs>
		<li
			v-if="getAppendDetails?.href"
			class="nav-item nav-item-append ms-lg-auto"
		>
			<a
				class="nav-link border fw-bold text-black bg-white border-secondary-3 small px-3 nav-link-append"
				:href="getAppendDetails.href"
				target="_blank"
			>
				View Live
				<FontAwesomeIcon
					:icon="lookupIcon(getAppendDetails.icon || 'arrow-up-right', 'fas')"
					class="fa-fw me-2"
				/>
			</a>
		</li>
	</ul>
</template>

<script setup>
import { useNavStore } from "@/Store/navStore"
import { Link, router, usePage } from "@inertiajs/vue3"
import { useCommonPageProps } from "@/Composables/useCommonPageProps"
import { ref, computed, onMounted, watch, nextTick } from "vue"
import useNavRouting from "@/Composables/useNavRouting"
import { getHeaderItems, getNavItems } from "./AppNavigation"
import ShortcutToggle from "@/Components/Mod/ShortcutToggle.vue"
import { useProjectStore } from "@/Store/projectStore"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { usePreferenceStore } from "@/Store/adminPreferenceStore"

import route from "ziggy-js"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const projectStore = useProjectStore()
import { MDBTabItem, MDBTabs, MDBTooltip } from "mdb-vue-ui-kit"
import FormField from "@/Components/Mod/FormField.vue"

import NotificationsModal from "./NotificationsModal.vue"
import { usePresenceStore } from "@/Store/adminPresenceStore"
import { useCurrentTeamStore } from "../../../Store/currentTeamStore"
import TeamNav from "@/Components/TeamNav.vue"
import UserNav from "@/Components/UserNav.vue"
import IconBadge from "@/Components/IconBadge.vue"
import { storeToRefs } from "pinia"
import { useDataModelStore } from "@/Store/dataModelStore"
import { useFeatureCheck } from "@/Composables/useFeatureCheck"
let props = defineProps({
	activeProject: {
		type: Object,
		default: () => ({}),
	},
	currentAdmin: {
		type: Object,
		default: () => ({}),
	},
	teamsCount: {
		type: Number,
		default: 1,
	},
})

const { userHasFeature } = useFeatureCheck()
const presenceTooltip = ref(null)

const preferenceStore = usePreferenceStore()
const presenceStore = usePresenceStore()
const navStore = useNavStore()

const { userList } = storeToRefs(presenceStore)

const pageStore = useDataModelStore()
const { dataModel } = storeToRefs(pageStore)

const { currentTeam, user } = useCommonPageProps()
const {
	pageTitle,
	projectName,
	activeComponent,
	bookmark,
	topBreadcrumb,
	breadCrumbs,
	showTabs,
	currentTab,
	pageTitleAppend,
} = storeToRefs(navStore)

const showTabsComputed = computed(() =>
	showTabs.value.map((tab) => ({ text: tab.title, value: tab.tabId })),
)

const getInitials = (name) => {
	if (!name) {
		return ""
	}
	const words = name.split(" ")
	const initials = words.map((word) => word[0]).join("")
	return initials
}

const page = usePage()
const isSuperAdmin = computed(() => page.props.isSuperAdmin)
const isSystemRoute = computed(() => page.props.routeList === "system")

const itemsHeader = computed(() => {
	const page = usePage()
	const project = page.props.project
	const features = page.props.dashboardMaskFeatures
	const permissions = page.props.userPermissions
	return getHeaderItems(activeComponent.value, project, features, permissions)
})
const isMounted = ref(false)
const showMainMenu = ref(false)
onMounted(() => {
	isMounted.value = true
})
const changeTab = (modelValue) => {
	if (isMounted.value) {
		// prevent mounting from double updating hash
		// document.location.hash = modelValue
		// history.pushState(null, null, "#" + modelValue)
	}
}
const { navItemClicked, handleItemClicked, href, hrefComputed } =
	useNavRouting(props)
const navigate = (linkHref) => {
	router.visit(linkHref)
}
watch(
	showTabs,
	(newVal, oldVal) => {
		if (
			newVal.every(
				(tab, index) =>
					JSON.stringify(tab) === JSON.stringify(oldVal?.[index] ?? {}),
			)
		)
			return
		const hash = window.location.hash.slice(1)
		if (hash.length > 0) {
			currentTab.value = hash
		} else {
			currentTab.value = showTabs.value?.[0]?.tabId ?? null
		}
	},
	{ deep: true },
)

const organizationItems = computed(() => {
	const page = usePage()
	const permissions = page.props.userPermissions
	return getNavItems("organizations", null, null, permissions)
})

const userItems = computed(() => {
	const page = usePage()
	const permissions = page.props.userPermissions
	return getNavItems("user", null, null, permissions)
})

const { adminPreferences } = storeToRefs(preferenceStore)
const expanded = computed(() => adminPreferences.value.sidenav === "expanded")
const toggleExpand = () => {
	if (expanded.value) {
		preferenceStore.setPreference("sidenav", "icons")
	} else {
		preferenceStore.setPreference("sidenav", "expanded")
	}
}
const pageTitleAppendClicked = () => {
	const href =
		typeof pageTitleAppend.value.href === "function"
			? pageTitleAppend.value.href()
			: pageTitleAppend.value.href

	if (href) {
		router.visit(href)
	}
}

const standardShowTabs = computed(() => {
	return showTabs.value.filter((tab) => {
		return !tab.append
	})
})

const appendShowTabs = computed(() => {
	return showTabs.value.filter((tab) => {
		return tab.append
	})
})

/**
 * The external link associated with the page.
 * This is defined in $page->custom_settings['top_nav_link_page']
 * The backend creates the link for you and assigns it to top_nav_link
 */
const getAppendDetails = computed(() => {
	let newHref = ""

	newHref = pageStore.getModelValue({
		name: "top_nav_link",
	})

	return {
		href: newHref,
		icon: pageStore.getModelValue({
			name: "top_nav_icon",
		}),
	}
})
</script>

<style>
.showTabs input {
	padding: 0.25rem 1rem !important;
	line-height: 18px !important;
	border-radius: 25px;
	font-weight: 700;
	height: 28px !important;
	color: inherit;
	border-color: #e3e4e6 !important;
	background-color: white !important;
	color: #222222 !important;
}
.showTabs + .select-dropdown-container .select-dropdown {
	border-radius: 10px;
	box-shadow: none;
	border: 1px solid #cecfd4;
}
.showTabs + .select-dropdown-container .select-options-list {
	display: flex;
	flex-direction: column;
	gap: 7px;
	margin: 5px;
}
.showTabs + .select-dropdown-container .select-option {
	border-radius: 5px;
}
.showTabs + .select-dropdown-container .select-option.selected {
	background-color: #d5d6ff;
}
.showTabs + .select-dropdown-container .select-option-text {
	font-weight: 700;
	font-size: 0.875rem;
}
.showTabs .select-option {
	--mdb-form-outline-select-option-font-size: 14px;
}
</style>
<style scoped>
@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #000596b3;
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 8px #00059600;
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #00059600;
	}
}
.activeUser {
	box-shadow: 0 0 0 0 #000596;
	transform: scale(1);
	animation: pulse 2s infinite;
}
.header {
	margin: 0 -30px;
	padding: 0 30px;
}
.sticky-top {
	z-index: 100;
}
.mobile-menu {
	cursor: pointer;
	box-sizing: border-box !important;
}
.mobile-menu {
	width: 36px;
	height: 36px;
}
.mobile-logo {
	width: 50px;
	height: 22.8px;
}
.left-header {
	gap: 10px;
	min-width: 0;
}
#page-title {
	display: inline-block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
#page-title-append {
	display: inline-flex;
	gap: 5px;
	align-items: center;
	justify-content: center;
}
.breadcrumb-text {
	font-size: 1.125rem;
}
.nav-tabs {
	padding: 10px 30px;
	margin: 0 -30px;
	/* min-height: 50px; */
}

.chevron {
	font-size: 0.625em;
}
.system-route-banner {
	font-size: 0.875rem;
	font-weight: 600;
}
@media (min-width: 769px) {
	.mobile-menu,
	.mobile-logo {
		display: none;
	}
}
@media (max-width: 767px) {
	.showTabs ~ li[role="presentation"] {
		display: none;
	}
	.header {
		margin-bottom: 50px;
	}
	.nav-tabs {
		margin-bottom: -20px;
	}
	.header {
		margin: 0 -10px;
		padding: 0 10px;
	}
	.breadcrumb-text {
		position: absolute;
		justify-content: start !important;
		padding: 0 10px;
		top: 100%;
		margin: 0 -10px;
		width: 100%;
		height: 50px;
		border-bottom: 1px solid #e3e4e6;
	}
	.nav-tabs {
		padding: 10px 10px;
		margin: 0 -10px;
	}
	#page-title-append {
		margin-left: auto !important;
	}
}
@media (max-width: 300px) {
	.header {
		margin-bottom: 0 !important;
	}
	.breadcrumb-text {
		display: none !important;
	}
}
</style>
