<script setup>
import { computed } from "vue"
import { Link } from "@inertiajs/vue3"
import { useProjectStore } from "@/Store/projectStore"
import route from "ziggy-js"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { usePage } from "@inertiajs/vue3"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const projectStore = useProjectStore()
const page = usePage()
const computedUserEmail = computed(() => page.props.auth.user.email)
const recentProjectList = computed(() => [])

const props = defineProps({
	includeHeader: {
		type: Boolean,
		default: true,
	},
})
const emit = defineEmits(["closeMenu"])
const closeParentMenu = () => {
	emit("closeMenu")
}
const openInNewTab = (href) => {
	Object.assign(document.createElement("a"), {
		target: "_blank",
		rel: "noopener noreferrer",
		href: href,
	}).click()
}
</script>

<template>
	<li
		v-for="(value, key) in recentProjectList"
		:key="key"
		class="list-group-item"
	>
		<div class="d-flex dropdown-item px-0">
			<div class="flex-grow-1">
				<Link
					class="pe-5 w-100 dropdown-item-recent"
					:href="route('dashboard.projects.dashboard', { project: value.id })"
					@click="closeParentMenu()"
				>
					<FontAwesomeIcon
						:icon="lookupIcon('circle', 'fas')"
						class="me-2 sub-menu"
					/>
					{{ value.name }}
				</Link>
			</div>
			<div>
				<a
					class="dropdown-item-recent"
					href="#"
					@click.prevent="
						openInNewTab(
							route('dashboard.projects.dashboard', { project: value.id }),
						)
					"
				>
					<FontAwesomeIcon
						:icon="lookupIcon('up-right-from-square', 'fas')"
						class="me-2 sub-menu"
					/>
				</a>
			</div>
		</div>
	</li>
</template>

<style scoped>
.dropdown-item-recent {
	--mdb-dropdown-state-color: #16181b;
	--mdb-dropdown-state-background-color: #eee;
	padding: var(--mdb-dropdown-item-padding-y) var(--mdb-dropdown-item-padding-x);
	color: var(--mdb-dropdown-color);
	border-radius: 0;
	display: block;
	width: 100%;
	padding: var(--mdb-dropdown-item-padding-y) var(--mdb-dropdown-item-padding-x);
	clear: both;
	font-weight: 400;
	color: var(--mdb-dropdown-link-color);
	text-align: left;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}
.dark .dropdown-item-recent {
	color: #ffffff !important;
}
</style>
