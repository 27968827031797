export const AI_PLATFORM = "ai_platform"
export const AGENDA = "agenda" // + Locations
export const AGENDA_SIDEBAR = "agenda_sidebar"
export const ATTENDEES = "attendees"
export const ATTENDEES_SIDEBAR = "attendees_sidebar"
export const BADGING = "badging"
export const FORMS = "forms"
export const FORMS_SIDEBAR = "forms_sidebar"
export const BADGING_SIDEBAR = "badging_sidebar"
export const COMMUNICATION = "communication"
export const COMMUNICATION_SIDEBAR = "communication_sidebar"
export const CUSTOM_DASHBOARDS = "custom_dashboards"
export const EXPORTER = "exporter"
export const FULL_SIDE_NAVIGATION = "full_side_navigation"
export const GROUPS = "groups"
export const HEADER_MENU = "header_menu"
export const IMPORTER = "importer"
export const LOGS_SIDEBAR = "logs_sidebar"
export const PORTALS = "portals"
export const SCANNING = "scanning" // + Access Points/Readers/Attendee Tags
export const SOCIAL_STREAMS = "social_streams"
