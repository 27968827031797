<template>
	<div class="notification-list fw-light">
		<div id="notification-toolbar" class="mb-2 px-2">
			<MDBRow class="text-start align-items-center">
				<MDBCol col="auto" class="title smallest fw-bold">Notifications</MDBCol>
				<MDBCol
					v-if="notificationStore.getUnreadNotificationCount > 0"
					col="auto"
					class="ms-auto"
				>
					<!-- mark all as read button -->
					<MDBBtn
						color="link"
						size="sm"
						:disabled="processingMarkAllAsRead"
						class="text-primary mark-as-read"
						disable-ripple
						@click="markAllAsRead"
					>
						<MDBSpinner v-if="processingMarkAllAsRead" size="sm" />
						<span v-else>
							<FontAwesomeIcon
								:icon="lookupIcon('check', 'fas')"
								class="me-1"
							/>
							Mark all as read
						</span>
					</MDBBtn>
				</MDBCol>
			</MDBRow>
		</div>
		<ul
			v-mdb-infinite-scroll="infiniteScroll1"
			class="container custom-scrollbar list-group p-0"
			style="max-height: 350px; overflow-y: scroll"
		>
			<li
				v-for="item in notificationList"
				:key="item.id"
				class="border-0 rounded d-flex align-items-center p-0 my-1 notification-list-item list-group-item"
			>
				<Link
					v-if="item.data.href"
					class="notification-link btn btn-link btn-sm text-start text-black p-2 m-0 shadow-0"
					:class="[
						{
							'bg-primary-10': !item.read_at,
						},
					]"
					:href="item.data.href"
					role="button"
					@click="handleClick(item)"
				>
					<p class="fw-bold mb-1 smaller">{{ item.data.title }}</p>
					<p class="fw-light lh-1 my-2 small">{{ item.data.body }}</p>
					<small class="fw-light smallest">{{
						timeSinceTimestamp(item.created_at)
					}}</small>
				</Link>
				<button
					v-else-if="item.data.download_url"
					class="notification-link btn btn-link btn-sm text-start text-black p-2 m-0 shadow-0"
					:class="[
						{
							'bg-primary-10': !item.read_at,
						},
					]"
					role="button"
					@click="handleDownload(item)"
				>
					<p class="fw-bold mb-1 smaller">{{ item.data.title }}</p>
					<p class="fw-light lh-1 my-2 small">{{ item.data.body }}</p>
					<p class="fw-light my-1">
						<FontAwesomeIcon
							v-if="!itemsDownloading[item.id]"
							:icon="lookupIcon('download', 'fas')"
							class="me-1"
						/>
						<MDBSpinner
							v-if="itemsDownloading[item.id]"
							size="sm"
							class="me-1"
							style="height: 1em; width: 1em"
						/>
						{{
							itemsDownloading[item.id]
								? "Downloading..."
								: item.data.action_text
						}}
					</p>
					<small class="fw-light smallest">{{
						timeSinceTimestamp(item.created_at)
					}}</small>
				</button>
			</li>
		</ul>
		<div
			v-if="isLoadingMore && noResults == false"
			class="d-flex justify-content-center align-items-center"
			style="height: 50px"
		>
			<MDBSpinner color="primary" />
		</div>
		<div v-if="noResults == true" class="mt-3 small">
			You have no notifications!
		</div>
	</div>
</template>

<script setup>
import {
	mdbInfiniteScroll as vMdbInfiniteScroll,
	MDBListGroup,
	MDBListGroupItem,
	MDBSwitch,
	MDBRow,
	MDBCol,
	MDBBtn,
	MDBTooltip,
	MDBSpinner,
} from "mdb-vue-ui-kit"
import { onMounted, ref, watch } from "vue"
import { formatTimestamp } from "@/Utils/dateHelpers"
import { Link, usePage } from "@inertiajs/vue3"
import { useNotificationStore } from "@/Store/notificationStore"
import notificationIcons from "@/Data/notificationIcons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { downloadFile } from "@/Composables/useDownloadFile"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

const emit = defineEmits(["close"])

const notificationStore = useNotificationStore()
const hoveringIndex = ref(null)
const notificationList = ref([])
const noResults = ref(false)
const itemIndex1 = ref(0)
const isLoadingMore = ref(false)
const infiniteScroll1 = async () => {
	await fetchNotifications()
}

const lastPaginatedResults = ref(null)
const showOnlyUnread = ref(false)
const processingReadUnread = ref({})
const totalMessages = ref(0)
/**
 * watch inputs
 */
watch(
	() => showOnlyUnread.value,
	() => {
		fetchNotifications(true)
	},
)

const timeSinceTimestamp = (timestamp) => {
	const then = new Date(timestamp).getTime()
	const now = Date.now()
	const elapsedTime = now - then

	let numSeconds = Math.floor(elapsedTime / 1000)
	if (numSeconds >= 3600 * 24) {
		const numDays = Math.floor(numSeconds / (3600 * 24))
		return `${numDays} days ago`
	} else if (numSeconds > 3600) {
		const numHours = Math.floor(numSeconds / 3600)
		return `${numHours} hours ago`
	} else if (numSeconds > 60) {
		const numMinutes = Math.floor(numSeconds / 60)
		return `${numMinutes} minutes ago`
	} else {
		return `${numSeconds} seconds ago`
	}
}

const fetchNotifications = async (reset = false) => {
	noResults.value = false
	isLoadingMore.value = true
	const page = reset ? 1 : (lastPaginatedResults.value?.current_page + 1 ?? 1)
	if (page == 1) {
		notificationList.value = []
	}
	const endpoint = route("dashboard.notifications.index", {
		page: page,
		per_page: 10,
		only_unread: showOnlyUnread.value,
		team: usePage().props.currentTeam.slug,
	})
	try {
		const response = await http.get(endpoint)
		const { data } = response
		const { notifications, total_count } = data
		lastPaginatedResults.value = notifications
		totalMessages.value = total_count
		if (lastPaginatedResults.value.total == 0) {
			noResults.value = true
			return
		}

		notificationList.value.push(...lastPaginatedResults.value.data)
	} catch (err) {
		console.error(err)
	}
	isLoadingMore.value = false
}

const handleClick = (item) => {
	emit("close")
	markAsRead(item)
}

const itemsDownloading = ref({})

const handleDownload = async (item) => {
	try {
		itemsDownloading.value = Object.assign(
			{},
			{
				...itemsDownloading.value,
				[item.id]: true,
			},
		)
		console.log("item:", item)
		await downloadFile(item.data?.download_url)
		itemsDownloading.value = Object.assign(
			{},
			{
				...itemsDownloading.value,
				[item.id]: false,
			},
		)
		markAsRead(item)
	} catch (error) {
		console.error("Download failed:", error)
		itemsDownloading.value = Object.assign(
			{},
			{
				...itemsDownloading.value,
				[item.id]: false,
			},
		)
	}
}

const markAsRead = async (item) => {
	console.log("marking as read: ", item)
	const markAsReadEndpoint = route("dashboard.notifications.mark-as-read", {
		id: item.id,
		team: usePage().props.currentTeam.slug,
	})

	processingReadUnread.value = Object.assign({}, processingReadUnread.value, {
		[item.id]: true,
	})

	try {
		const response = await http.post(markAsReadEndpoint, {})
		const { data } = response
		const { unread_count } = data
		console.log("unread count", unread_count)
		// update the item's read_at status by finding it in the list
		const index = notificationList.value.findIndex((i) => i.id == item.id)
		if (index > -1) {
			let updatedItem = { ...notificationList.value[index], read_at: "read" }
			notificationList.value.splice(index, 1, updatedItem)
		}

		notificationStore.setUnreadNotificationCount(unread_count)

		processingReadUnread.value[item.id] = false
	} catch (err) {
		console.error(err)
		processingReadUnread.value[item.id] = false
	}
}

/**
 * mark all as read
 */
const processingMarkAllAsRead = ref(false)
const markAllAsRead = async () => {
	processingMarkAllAsRead.value = true
	const markAllAsReadEndpoint = route(
		"dashboard.notifications.mark-all-as-read",
		{
			team: usePage().props.currentTeam.slug,
		},
	)

	try {
		const response = await http.post(markAllAsReadEndpoint, {})
		const { data } = response

		// update the item's read_at status by finding it in the list
		notificationList.value.forEach((item) => {
			item.read_at = "read"
		})

		notificationStore.setUnreadNotificationCount(0)

		processingMarkAllAsRead.value = false
	} catch (err) {
		console.error(err)
		processingMarkAllAsRead.value = false
	}
}

onMounted(() => {
	fetchNotifications()
})
</script>

<style scoped>
.notification-list .notification-list-item:hover {
	cursor: pointer;
}

.custom-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: #888 #f1f1f1;
	overflow: auto !important;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.read {
	background-color: transparent !important;
}
.notification-link * {
	text-transform: none;
}
.mark-as-read {
	text-transform: none;
}
</style>
