<template>
	<div class="hidden">
		<span id="zendesk_user_name">{{ user.name }}</span>
		<span id="zendesk_user_email">{{ user.email }}</span>
	</div>
	<div
		v-if="zendeskLoaded"
		id="customZendesk"
		class="bg-primary text-white fw-bold"
		@click="openWidget"
	>
		<FontAwesomeIcon :icon="lookupIcon('headset', 'fas')" class="me-1" />
		Tech Support
	</div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

import { initWidget, openWidget } from "@/Composables/useZendesk"
import { onMounted, ref } from "vue"
const props = defineProps({
	user: {
		type: Object,
		required: true,
		default: () => {
			return {
				name: "No User Found",
				id: null,
				email: "",
			}
		},
	},
})

const zendeskLoaded = ref(false)
const initVars = JSON.parse(atob(window.efboot))
const formId =
	initVars && initVars.zendesk_form_id ? initVars.zendesk_form_id : null

onMounted(() => {
	if (zendeskLoaded.value) return
	appendZendeskScript()
	const waitingForZendesk = setInterval(() => {
		if (window.zE && window.$zopim && window.zE.identify) {
			clearInterval(waitingForZendesk)
			initWidget(props.user.name, props.user.email)
			zendeskLoaded.value = true
		}
	}, 100)
})

const appendZendeskScript = () => {
	window.zESettings = {
		webWidget: {
			chat: {
				suppress: false,
			},
			contactForm: {
				ticketForms: [
					{
						id: formId,
					},
				],
				suppress: false,
			},
			helpCenter: {
				suppress: true,
			},
			talk: {
				suppress: true,
			},
			answerBot: {
				suppress: true,
			},
		},
	}
	const zendesk = document.createElement("script")
	zendesk.setAttribute(
		"src",
		"https://static.zdassets.com/ekr/snippet.js?key=858d8a0c-9d12-4331-b977-ddf57906e52f",
	)
	zendesk.setAttribute("id", "ze-snippet")
	document.head.appendChild(zendesk)
}
</script>

<style>
.hidden {
	display: none;
}
/*------------Custom Zendesk-------------*/

#customZendesk {
	padding: 2px 20px;
	border-radius: 10px;
	z-index: 9999999;
	cursor: pointer;
	opacity: 1;
	transition: 0.3s ease-in-out;
	border-radius: 10px 10px 0 0;
	position: fixed;
	bottom: 148px;
	right: -59px;
	transform: rotate(-90deg);
}
@media (min-width: 768px) {
	#customZendesk {
		right: -66px;
	}
}
iframe#launcher {
	display: none;
}
</style>
