<template>
	<li :class="['nav-item w-100', { active: menu }, item.classList || []]">
		<MDBTooltip
			v-model="tooltip"
			class="d-block w-100"
			:disabled="menu || expanded"
			offset="0,15"
			direction="right"
		>
			<template #reference>
				<MDBDropdown
					v-if="item.children"
					v-model="menu"
					class="w-100"
					dropend
					:offset="dropdownOffset"
					:popper-config="{ strategy: 'fixed' }"
				>
					<MDBDropdownToggle
						tag="a"
						:class="`d-flex align-items-center rounded-pill text-white text-decoration-none ${
							selectedNavItem ? 'active' : ''
						}`"
						:title="item.title"
						@click.prevent="((menu = !menu), (tooltip = !tooltip && !expanded))"
					>
						<FontAwesomeIcon :icon="lookupIcon(item.icon, 'far')" size="lg" />
						<div class="nav-item__title me-auto ms-2">{{ item.title }}</div>
					</MDBDropdownToggle>

					<MDBDropdownMenu
						class="border-secondary border"
						aria-labelledby="dropdownMenuButton"
					>
						<RecentProjectsList
							v-if="item.type == 'recent_project_list'"
							@close-menu="menu = false"
						/>
						<BaseNavSubItem
							v-for="(child, i) in item.children"
							v-else-if="item.children"
							:key="i"
							:item="child"
							@close-menu="menu = false"
						/>
					</MDBDropdownMenu>
				</MDBDropdown>
				<a
					v-else-if="item.inertia === false"
					:class="`d-flex align-items-center rounded-pill text-white text-decoration-none ${
						selectedNavItem ? 'active' : ''
					}`"
					:href="href || '#'"
					:target="item.target || '_self'"
					@click="handleItemClicked(item, $event)"
				>
					<FontAwesomeIcon :icon="lookupIcon(item.icon, 'far')" size="lg" />
					<div class="nav-item__title me-auto ms-2">{{ item.title }}</div>
				</a>
				<Link
					v-else
					:class="`d-flex align-items-center rounded-pill text-white text-decoration-none ${
						selectedNavItem ? 'active' : ''
					}`"
					:href="href || '#'"
					@click="handleItemClicked(item, $event)"
				>
					<FontAwesomeIcon :icon="lookupIcon(item.icon, 'far')" size="lg" />
					<div class="nav-item__title me-auto ms-2">{{ item.title }}</div>
				</Link>
			</template>
			<template #tip>
				{{ item.title }}
			</template>
		</MDBTooltip>
	</li>
</template>

<script setup>
import useNavRouting from "@/Composables/useNavRouting"
import BaseNavSubItem from "@/Components/BaseNavSubItem.vue"
import ApplicationMark from "@/Components/ApplicationMark.vue"
import RecentProjectsList from "@/Components/RecentProjectsList.vue"
import { useCommonPageProps } from "@/Composables/useCommonPageProps"
const { currentTeam } = useCommonPageProps()

import { Link } from "@inertiajs/vue3"
import { onMounted, onUnmounted, computed, ref, defineEmits, watch } from "vue"
import {
	MDBTooltip,
	MDBDropdownMenu,
	MDBDropdown,
	MDBDropdownToggle,
} from "mdb-vue-ui-kit"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

const props = defineProps({
	item: {
		type: Object,
		default: () => ({
			href: undefined,
			icon: undefined,
			title: undefined,
			to: undefined,
		}),
	},
	text: {
		type: Boolean,
		default: false,
	},
	selectedNavItem: {
		type: Boolean,
		default: false,
	},
	expanded: {
		type: Boolean,
		default: false,
	},
})
const tooltip = ref(false)
const menu = ref(false)
const { navItemClicked, handleItemClicked, href } = useNavRouting(props)

const viewportWidth = ref(window.innerWidth)
const dropdownOffset = computed(() => {
	if (viewportWidth.value > 767) return "0,15"
	else return "0,-200"
})
const resizeListener = ({ target }) => {
	viewportWidth.value = target.innerWidth
}
onMounted(() => {
	window.addEventListener("resize", resizeListener)
})
onUnmounted(() => {
	window.removeEventListener("resize", resizeListener)
})
</script>
<style scoped>
.dropdown-toggle::after {
	display: none;
}
.dropdown-menu li:nth-child(n + 2) {
	border-top: 1px solid var(--mdb-border-color);
}
.nav-item a {
	height: 39px;
	transition: 0.15s ease-in-out;
	overflow: hidden;
}
.nav-item a.active {
	background-color: #6b6fea;
}
.nav-item a:hover,
.nav-item.active a {
	background-color: #3f45e4;
}
.nav-item i,
.nav-item svg {
	flex: 0 0 39px;
	text-align: center;
}
.nav-item__title {
	display: none;
	font-size: 12px;
	white-space: nowrap;
}
.expanded .nav-item__title {
	display: inline;
}
.dropdown-menu {
	z-index: 999999999999;
}
@media (max-width: 767px) {
	.dropdown-menu {
		max-height: 80vh;
		overflow: auto;
	}
}
</style>
