export const initWidget = (username, email) => {
	const zE = window.zE
	const $zopim = window.$zopim
	zE(function () {
		// Used for live chat
		$zopim(function () {
			if (!$zopim.livechat.isChatting()) {
				$zopim.livechat.clearAll()
			}
			$zopim.livechat.setOnConnected(() => {
				$zopim.livechat.set({
					name: username,
					email: email,
				})
			})
		})

		// Used for forms
		zE.identify({
			name: username,
			email: email,
		})
	})

	zE("webWidget:on", "close", () => {
		document.getElementById("customZendesk").style.opacity = 1
	})
}

export const openWidget = () => {
	const zE = window.zE
	document.getElementById("customZendesk").style.opacity = 0
	zE("webWidget", "open")
}
