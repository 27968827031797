<template>
	<template v-if="item.type && item.type == 'recent-projects'">
		<div class="divider-title text-primary">
			{{ item.title }}
		</div>
		<li v-for="(project, index) in recentProjectList" :key="index">
			<Link
				class="dropdown-item p-3 pe-5"
				:headers="{
					'x-recent-project': project.id,
					'x-recent-team': project.team_id,
					'x-recent-admin': currentAdminId,
				}"
				:href="
					route('dashboard.project.default-dashboard', {
						project: project.slug,
						team: project.team_slug,
					})
				"
				@click="
					(handleItemClicked(
						{
							route: route('dashboard.project.default-dashboard', {
								project: project.slug,
								team: project.team_slug,
							}),
							recentProject: project.id,
							recentTeam: project.team_id,
						},
						$event,
					),
					closeParentMenu())
				"
			>
				<FontAwesomeIcon
					v-if="project.icon"
					:icon="lookupIcon(project.icon, 'far')"
					size="lg"
					class="fa-fw me-2"
				/>
				<span>
					{{ project.name }}
					<div
						v-if="project.preview_mode"
						class="badge rounded-pill badge-preview"
					>
						Preview
					</div>
				</span>
			</Link>
		</li>
	</template>
	<li v-else-if="item.type && item.type == 'divider'">
		<hr class="dropdown-divider p-0 m-0" />
	</li>
	<li v-else>
		<a
			v-if="item.inertia === false"
			class="dropdown-item p-3 pe-5"
			:rel="href && href !== '#' ? 'noopener' : undefined"
			:href="href || '#'"
			:target="item.target || '_self'"
			@click="(handleItemClicked(item, $event), closeParentMenu())"
		>
			<FontAwesomeIcon
				v-if="item.icon"
				:icon="lookupIcon(item.icon, 'far')"
				size="lg"
				class="fa-fw me-2"
			/>
			<span v-if="item.type == 'team-name'"
				><strong>{{ currentTeam.name }}</strong></span
			>
			<span v-else>{{ item.title }}</span>
			<MDBBadge
				v-if="item.type == 'notifications'"
				class="ms-2"
				badge="danger"
				pill
			>
				1
			</MDBBadge>
		</a>
		<Link
			v-else
			class="dropdown-item p-3 pe-5"
			:rel="href && href !== '#' ? 'noopener' : undefined"
			:href="href || '#'"
			@click="(handleItemClicked(item, $event), closeParentMenu())"
		>
			<FontAwesomeIcon
				v-if="item.icon"
				:icon="lookupIcon(item.icon, 'far')"
				size="xl"
				class="fa-fw me-2"
			/>
			<template v-if="item.logo && item.logo === 'team-initial'">
				<div
					class="text-uppercase d-inline-flex justify-content-center align-items-center bg-primary text-white p-1 rounded-circle border initials ms-0 me-1"
				>
					{{ getInitials(item.title) }}
				</div>
			</template>
			<div
				v-else-if="
					item.logo && item.logo.length > 0 && item.logo !== 'team-initial'
				"
				class="d-inline-block rounded-circle me-1"
			>
				<img
					:src="item.logo"
					:alt="item.title"
					class="rounded-circle"
					style="width: 30px; height: 30px"
				/>
			</div>
			<span v-if="item.type == 'team-name'"
				><strong>{{ currentTeam.name }}</strong></span
			>
			<span v-else>{{ item.title }}</span>
			<MDBBadge
				v-if="item.type == 'notifications'"
				class="ms-2"
				badge="danger"
				pill
			>
				1
			</MDBBadge>
		</Link>
		<ul
			v-if="item.children"
			class="dropdown-menu dropdown-submenu border border-secondary"
		>
			<BaseNavSubItem
				v-for="(child, i) in item.children"
				:key="i"
				:item="child"
				@close-menu="((menu = false), closeParentMenu())"
			/>
		</ul>
	</li>
</template>

<script setup>
import useNavRouting from "@/Composables/useNavRouting"
import { useCommonPageProps } from "@/Composables/useCommonPageProps"
import { useProjectStore } from "@/Store/projectStore"

import { Link, usePage, router } from "@inertiajs/vue3"
import { computed, ref } from "vue"
import { MDBBadge } from "mdb-vue-ui-kit"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import route from "ziggy-js"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const page = usePage()
const projectStore = useProjectStore()

const { currentTeam } = useCommonPageProps()

const currentAdminId = computed(() => {
	return page.props.auth.user.id || 0
})
const props = defineProps({
	item: {
		type: Object,
		default: () => ({
			href: undefined,
			icon: undefined,
			title: undefined,
			to: undefined,
		}),
	},
})
const emit = defineEmits(["closeMenu"])
const closeParentMenu = () => {
	emit("closeMenu")
}

const tooltip = ref(false)
const showSubMenu = ref(false)
const { navItemClicked, handleItemClicked, href } = useNavRouting(props)
const recentProjectList = computed(() => {
	if (page.props.auth) {
		return projectStore.getRecentProjects(page.props.auth.user.id)
	}
	return []
})
const getInitials = (name) => {
	const nameArr = name.split(" ")
	if (nameArr.length > 1) {
		return nameArr[0][0] + nameArr[1][0]
	}
	return nameArr[0][0]
}
</script>
<style scoped>
.drop-shadow {
	filter: drop-shadow(1px 1px);
	font-size: 18px;
	line-height: 18px;
}
.dropdown-menu > *:nth-child(n + 2) {
	border: none;
	border-top: 1px solid var(--mdb-border-color);
}
.divider-title {
	font-size: 12px;
	text-align: center;
	padding: 7.5px;
	font-weight: 500;
}
.list-group-item-action {
	padding-left: 0;
	white-space: nowrap;
}

a {
	text-align: left;
}
.initials {
	width: 30px;
	height: 30px;
	border-color: #f3f3f3 !important;
	font-size: 14px;
}
</style>
