// Utilities
import { get } from "lodash"
import { defineStore } from "pinia"
const TEAM_LIST_LOCAL_STORAGE_KEY = "team-list"

export const useCurrentTeamStore = defineStore("current-team", {
	state: () => ({
		currentTeam: {},
		recentTeamList:
			JSON.parse(localStorage.getItem(TEAM_LIST_LOCAL_STORAGE_KEY)) || {},
		adminId: "",
	}),
	getters: {
		getCurrentTeam() {
			if (this.currentTeam === undefined) return null
			return this.currentTeam
		},
		getRecentTeams() {
			if (this.adminId && this.recentTeamList[this.adminId]) {
				return this.recentTeamList[this.adminId]
			} else {
				return []
			}
		},
	},
	actions: {
		setCurrentTeam(adminId, value) {
			if (value === undefined) return
			this.currentTeam = value
			if (adminId) {
				this.adminId = adminId
				if (!this.recentTeamList[adminId]) {
					this.recentTeamList[adminId] = []
				}
				this.recentTeamList[adminId] = this.recentTeamList[adminId].filter(
					(team) => team.id !== value.id,
				)
				if (this.recentTeamList[adminId].length > 5) {
					this.recentTeamList[adminId] = this.recentTeamList[adminId].splice(
						0,
						4,
					)
				}
				this.recentTeamList[adminId].unshift(value)
				localStorage.setItem(
					TEAM_LIST_LOCAL_STORAGE_KEY,
					JSON.stringify(this.recentTeamList),
				)
			}
		},
	},
})
