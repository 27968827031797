import { router, usePage } from "@inertiajs/vue3"
import { useToast } from "./useToast"

export const useGlobalErrorToast = () => {
	const toast = useToast()
	const props = usePage().props
	// console.log("useGlobalErrorToast", props?.errorBags?.globalErrors)

	try {
		if (props?.errorBags?.globalErrors) {
			props.errorBags.globalErrors.forEach((error) => {
				console.log(error)
				setTimeout(() => {
					toast.error(error.join("\n"))
				}, 500)
			})
		}
	} catch (err) {
		console.error("useGlobalErrorToast", err)
	}
}
