<template>
	<div class="col-sm-auto bg-primary bg-gradient vh-100 sticky-top d-flex">
		<div class="d-flex flex-column my-1 flex-grow-1 w-100">
			<ul class="p-2 nav nav-global flex-column w-100">
				<li class="nav-item w-100 mb-2">
					<Link
						:href="route('dashboard.dashboard')"
						class="d-flex align-items-center text-white text-decoration-none"
					>
						<img
							src="/images/logo_light_text.png"
							class="platform-logo d-inline-block"
							:class="{ 'ms-2': expanded }"
						/>
					</Link>
				</li>
				<BaseNavItem
					v-for="(item, i) in itemsGlobal"
					:key="i"
					:item="item"
					:expanded="expanded"
					:selected-nav-item="selectedNavItem(item)"
				/>
			</ul>

			<hr v-if="activeProject || isSystemRoute" class="my-0 mx-auto" />
			<!-- Top Items -->
			<ul
				class="nav nav-project flex-column w-100 flex-nowrap overflow-auto p-2 hidden-scrollbar"
			>
				<BaseNavItem
					v-for="(item, i) in itemsTop"
					:key="i"
					:item="item"
					:expanded="expanded"
					:selected-nav-item="selectedNavItem(item)"
				/>
			</ul>

			<hr v-if="activeProject" class="my-0 mx-auto" />

			<!-- Bottom Items -->
			<ul class="nav nav-misc flex-column mt-auto w-100 p-2">
				<BaseNavItem
					v-if="
						projectShortcuts.children &&
						projectShortcuts.children.length &&
						userHasFeature('bookmarks')
					"
					:item="projectShortcuts"
					:expanded="expanded"
					:selected-nav-item="selectedNavItem(projectShortcuts)"
				/>
				<BaseNavItem
					v-for="(item, i) in itemsBottom"
					:key="i"
					:item="item"
					:expanded="expanded"
					:selected-nav-item="selectedNavItem(item)"
				/>
				<MDBBtn
					class="expand-nav rounded-pill p-0 m-0 bg-primary-7 shadow-0"
					:ripple="false"
					@click="toggleExpand"
				>
					<FontAwesomeIcon
						class="expand-icon"
						:icon="lookupIcon('chevrons-right', 'far')"
					/>
				</MDBBtn>
			</ul>
			<!-- <QuickMenu /> -->
		</div>
	</div>
</template>

<script setup>
import { computed, onUnmounted, watch } from "vue"
import { Link, usePage, router } from "@inertiajs/vue3"
import route from "ziggy-js"
import { getNavItems } from "./AppNavigation"
import { MDBBtn } from "mdb-vue-ui-kit"
import BaseNavItem from "@/Components/BaseNavItem.vue"
import { useCommonPageProps } from "@/Composables/useCommonPageProps"
import { usePreferenceStore } from "@/Store/adminPreferenceStore"
import { useNavStore } from "@/Store/navStore"
import { storeToRefs } from "pinia"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { useFeatureCheck } from "@/Composables/useFeatureCheck"

import { useProjectStore } from "@/Store/projectStore"
import { remove } from "lodash"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

const page = usePage()
const initVars = JSON.parse(atob(window.efboot))
const { userHasFeature } = useFeatureCheck()
const preferenceStore = usePreferenceStore()
const navStore = useNavStore()
const projectStore = useProjectStore()

const { activeComponent } = storeToRefs(navStore)
const { adminPreferences } = storeToRefs(preferenceStore)

const selectedNavItem = (item) => {
	if (item === undefined) return false
	if (item.component === activeComponent.value) return true
	if (
		item.activeComponents &&
		item.activeComponents.includes(activeComponent.value)
	) {
		return true
	}
	if (item.href && item.href === location.pathname) return true
	if (item.children && item.children.length) {
		return item.children.some((child) => {
			return (
				child.component === activeComponent.value ||
				(child.activeComponents &&
					child.activeComponents.length &&
					child.activeComponents.some(
						(component) => component === activeComponent.value,
					))
			)
		})
	}
	return false
}

const { activeProject } = useCommonPageProps()

const isSystemRoute = computed(() => {
	return page?.props?.routeList === "system"
})

const projectShortcuts = computed(() => {
	if (
		activeProject.value &&
		activeProject.value.id &&
		activeProject.value.shortcuts.length
	) {
		const activeShortcuts = activeProject.value.shortcuts.filter((shortcut) => {
			return !shortcut.is_top_level
		})
		const children = activeShortcuts.map((shortcut) => {
			return {
				...shortcut,
				title: shortcut.label,
				route: shortcut.navigation_path,
			}
		})
		return {
			title: "Bookmarks",
			icon: "bookmark",
			children: children,
		}
	} else {
		return []
	}
})
const expanded = computed(() => {
	return adminPreferences.value.sidenav === "expanded"
})

const itemsGlobal = computed(() => {
	const permissions = page.props.userPermissions
	return getNavItems("global", null, null, permissions)
})

const itemsTop = computed(() => {
	let navKey = "project-nav"

	if (page.props.routeList == "system") {
		navKey = "system-nav"
	}
	const project = page.props.project
	const features = page.props.dashboardMaskFeatures
	const permissions = page.props.userPermissions

	return getNavItems(navKey, project, features, permissions)
})

const itemsBottom = computed(() => {
	const permissions = page.props.userPermissions
	return getNavItems("lower-nav", null, null, permissions)
})

const toggleExpand = () => {
	if (expanded.value) {
		preferenceStore.setPreference("sidenav", "icons")
	} else {
		preferenceStore.setPreference("sidenav", "expanded")
	}
}

const removeNavigateListener = router.on("start", () => {
	if (window.innerWidth < 768) preferenceStore.setPreference("sidenav", "icons")
})

onUnmounted(() => {
	removeNavigateListener()
})
</script>
<style scoped>
.dropend .dropdown-toggle::after {
	display: none;
}
.active .badge {
	background-color: #000 !important;
	color: #fff !important;
}
.expand-nav {
	font-size: 12px;
	color: #fafafa;
	height: 20px;
	width: 100%;
}
.platform-logo {
	height: 17px;
	transition: 0.3s;
}
.expanded .platform-logo {
	height: 30px;
}
.nav-item__label {
	display: none;
}
.expanded .nav-item__label {
	display: inline-block;
}
ul {
	gap: 10px;
}
.nav-item a {
	height: 39px;
	transition: 0.3s ease-in-out;
	overflow: hidden;
}
.nav-item img {
	flex: 0 0 39px;
	text-align: center;
}
.nav-item__title {
	display: none;
	font-size: 12px;
	white-space: nowrap;
}
hr {
	color: #9598f0;
	width: 100%;
}
.expanded .nav-item__title {
	display: inline;
}
.expand-icon {
	transition: 0.3s;
}
.expanded .expand-icon {
	transform: scale(-1);
}
@media (max-width: 767px) {
	.expand-nav {
		display: none;
	}
	.nav-item__title {
		display: inline;
	}
	#main-sidebar {
		position: fixed;
		left: -100%;
		z-index: 9999999999;
		width: 220px;
	}
	.expanded #main-sidebar {
		left: 0;
	}
	#main-sidebar {
		transition: 0.3s ease-in-out;
	}
	.expanded #main-sidebar {
		max-width: calc(100% - 25px);
	}
}
</style>
