import { computed } from "vue"
import { usePage } from "@inertiajs/vue3"

const propExists = (propName) => {
	if (usePage() && usePage().props && usePage().props[propName]) {
		return true
	}
	return false
}

export const useCommonPageProps = () => {
	const currentTeam = computed(() => {
		if (!propExists("currentTeam")) return null
		return usePage().props.currentTeam
	})

	const activeProject = computed(() => {
		if (!propExists("project")) return null
		return usePage().props.project
	})

	const user = computed(() => {
		if (!propExists("auth") || !usePage().props.auth) return null
		return usePage().props.auth.user
	})

	const permissions = computed(() => {
		if (!propExists("permissions")) return null
		return usePage().props.permissions
	})

	const activeProjectId = computed(() => {
		if (activeProject.value) return activeProject.value.id
		return null
	})

	const attendeeTypes = computed(() => {
		const page = usePage()
		if (!page.props.attendeeTypes) {
			return []
		}
		return page.props.attendeeTypes
	})

	const attendeeFields = computed(() => {
		const page = usePage()
		if (!page.props.attendeeFields) {
			return []
		}
		return page.props.attendeeFields
	})

	return {
		activeProject,
		user,
		permissions,
		activeProjectId,
		attendeeTypes,
		currentTeam,
		attendeeFields,
	}
}
