// Utilities
import { defineStore } from "pinia"
import { ref } from "vue"

export const usePresenceStore = defineStore("admin_presence", () => {
	const userString = ref("")
	const userList = ref([])

	function setUserList(value) {
		this.userList = value
		this.userString = this.userList.map((u) => u.name).join(", ")
	}
	function addUser(value) {
		this.userList.push(value)
		this.userString = this.userList.map((u) => u.name).join(", ")
	}
	function removeUser(value) {
		this.userList = this.userList.filter((item) => item.id !== value)
		this.userString = this.userList.map((u) => u.name).join(", ")
	}

	return { userString, userList, setUserList, addUser, removeUser }
})
