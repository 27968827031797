<template>
	<div id="page-container" class="d-flex" :class="{ expanded }">
		<Head :title="title"></Head>
		<div id="main-content" class="container-fluid min-vh-100 order-1">
			<div class="d-flex flex-column m-0 col-12 main-content__inner p-0">
				<AppLayoutHeader
					:active-project="props.project"
					:current-admin="currentAdmin"
					:teams-count="props.teamsCount"
				/>
				<slot />
				<div
					class="footer border-top border-secondary-3 footer-links d-flex flex-column align-items-center bg-white"
				>
					<div
						class="d-flex flex-wrap flex-sm-nowrap justify-content-center w-100 align-items-end align-items-sm-center m-2"
					>
						<div class="text-black smaller">
							{{ new Date().getFullYear() }} &copy; Copyright
							<strong>Gramercy Tech</strong>
						</div>
						<ul class="list-inline m-0 ms-sm-auto smaller">
							<li class="list-inline-item">
								<a class="text-black" href="https://status.eventfinity.co"
									>Status Page</a
								>
							</li>
							<li class="list-inline-item">
								<a
									class="text-black"
									href="https://www.gramercytech.com/privacy-policy"
									>Privacy Policy</a
								>
							</li>
							<li class="list-inline-item">
								<a
									class="text-black"
									href="https://www.gramercytech.com/terms-of-service"
									>Terms of Service</a
								>
							</li>
						</ul>
					</div>
					<ZendeskWidget :user="currentAdmin" />
				</div>
			</div>
		</div>
		<div id="mobileOverlay" @click="toggleExpand"></div>
		<AppLayoutSidebar id="main-sidebar" />
	</div>
	<PWAReloadPrompt />
</template>

<script setup>
import PWAReloadPrompt from "@/Components/PWAReloadPrompt.vue"
import AppLayoutSidebar from "@/Dashboard/Layouts/Partials/AppLayoutSidebar.vue"
import AppLayoutHeader from "@/Dashboard/Layouts/Partials/AppLayoutHeader.vue"
import ZendeskWidget from "@/Components/ZendeskWidget.vue"
import { getNavComponent } from "./Partials/AppNavigation"

import { useProjectStore } from "@/Store/projectStore"
import { usePresenceStore } from "@/Store/adminPresenceStore"
import { router, usePage } from "@inertiajs/vue3"
import { Head } from "@inertiajs/vue3"
import { ref, computed, onMounted, watch, onUnmounted } from "vue"
import { useNavStore } from "@/Store/navStore"
import { useAssetStore } from "@/Store/assetStore"
import { usePreferenceStore } from "@/Store/adminPreferenceStore"
import { useCurrentTeamStore } from "@/Store/currentTeamStore"
import { useGlobalErrorToast } from "@/Composables/useGlobalErrorToast"
import { storeToRefs } from "pinia"

const props = defineProps({
	title: {
		type: String,
		default: "Eventfinity",
	},
	project: {
		type: Object,
		default: () => ({}),
	},
	presenceChannel: {
		type: String,
		default: "",
	},
	currentTeam: {
		type: Object,
		default: () => ({}),
	},
	teamsCount: {
		type: Number,
		default: 1,
	},
})

const currentTeamStore = useCurrentTeamStore()
const preferenceStore = usePreferenceStore()
const projectStore = useProjectStore()
const navStore = useNavStore()
const presenceStore = usePresenceStore()
const assetStore = useAssetStore()

const userList = ref([])

const currentPageVersion = ref("")

let currentPresenceChannel = null
const { activeComponent } = storeToRefs(navStore)
watch(
	() => props.currentTeam,
	(newTeam, oldTeam) => {
		let adminId = ""
		if (page.props.auth) {
			adminId = page.props.auth.user.id
		}
		currentTeamStore.setCurrentTeam(adminId, newTeam)
	},
)
watch(
	() => props.project,
	(newProject, oldProject) => {
		let adminId = ""
		if (page.props.auth) {
			adminId = page.props.auth.user.id
		}
		projectStore.setActiveProject(adminId, props.currentTeam?.slug, newProject)
		navStore.setProjectName(newProject?.name || "")
		assetStore.populateAssetList(newProject)
	},
)
const page = usePage()
const currentAdmin = computed(() => {
	const auth = page.props.auth
	if (auth && auth.hasOwnProperty("user")) {
		return auth.user
	} else {
		return {
			name: "No User Found",
			id: null,
		}
	}
})
const { adminPreferences } = storeToRefs(preferenceStore)
const expanded = computed(() => adminPreferences.value.sidenav === "expanded")
const adminTheme = preferenceStore.getPreference
onMounted(() => {
	navStore.setPageTitle("")
	navStore.setTopBreadcrumb({})
	navStore.setBookmark({})
	let adminId = ""
	if (page.props.auth) {
		adminId = page.props.auth.user.id
	}
	currentTeamStore.setCurrentTeam(adminId, props.currentTeam)
	projectStore.setActiveProject(adminId, props.currentTeam?.slug, props.project)
	navStore.setProjectName(props.project?.name || "")

	const project = page.props.project
	assetStore.populateAssetList(project)

	if (adminTheme && adminTheme["theme"]) {
		if (adminTheme["theme"] == "dark") {
			// document.body.classList.add("dark")
		}
	}
	useGlobalErrorToast()
})

// Not all components have a header nav and therefore wont be set as active
let lastComponent = ""
let authLayoutNavigationListener = router.on("navigate", (event) => {
	// Reset Page Title
	if (
		currentPageVersion.value !== event.detail.page.version ||
		event.detail.page.component != lastComponent
	) {
		// Dont reset if we're paginating a datatable or changing tabs
		currentPageVersion.value = event.detail.page.version
		navStore.setPageTitle("")
		navStore.setTopBreadcrumb({})
		navStore.setBookmark({})
	}
	lastComponent = event.detail.page.component
	// Set Active Component and Header Nav
	let headerComponent = getNavComponent(event.detail.page.component)

	if (headerComponent) {
		navStore.setActiveComponent(event.detail.page.component)
	} else {
		navStore.setActiveComponent("")
	}
	//set header nav
	if (props.presenceChannel.length > 0) {
		currentPresenceChannel = props.presenceChannel
		window.Echo.join(`${props.presenceChannel}`)
			.here((users) => {
				// ...
				userList.value = users.filter((u) => u.id !== currentAdmin.value.id)
				presenceStore.setUserList(users)
			})
			.joining((joiningUser) => {
				userList.value.push(joiningUser)
				presenceStore.addUser(joiningUser)
			})
			.leaving((leavingUser) => {
				userList.value = userList.value.filter((u) => u.id !== leavingUser.id)
				presenceStore.removeUser(leavingUser.id)
			})
			.error((error) => {
				console.error(error)
			})
	} else {
		if (currentPresenceChannel) {
			window.Echo.leave(`${currentPresenceChannel}`)
			currentPresenceChannel = null
		}
		presenceStore.setUserList([])
		userList.value = []
	}
})

let authLayoutInvalidListener = router.on("invalid", (errors) => {
	// if navigate returns an error, check if its
	let response = errors.detail?.response?.status || null
	if (response === 404) {
		let headers = errors.detail?.response?.config?.headers || []
		if (headers["x-recent-project"]) {
			let adminId = headers["x-recent-admin"]
			let teamId = headers["x-recent-team"]
			let projectId = headers["x-recent-project"]
			projectStore.removeFromRecents(adminId, projectId)
			// remove project from recent list
		}
	}
})

let popstateEventListener = window.addEventListener("popstate", (event) => {
	event.stopImmediatePropagation()

	router.reload({
		preserveScroll: false,
		preserveState: false,
		replace: true,
		onError: () => (window.location.href = event.state.url),
	})
})

onUnmounted(() => {
	authLayoutNavigationListener()
	authLayoutInvalidListener()
	removeEventListener("popstate", popstateEventListener)
})

const toggleExpand = () => {
	if (expanded.value) {
		preferenceStore.setPreference("sidenav", "icons")
	} else {
		preferenceStore.setPreference("sidenav", "expanded")
	}
}
</script>

<style lang="scss" scoped>
// :deep(.dropdown-menu) > *:nth-child(n + 2) {
// 	border: none;
// 	border-top: 1px solid var(--mdb-border-color);
// }
@media (min-width: 768px) {
	#main-content {
		max-width: calc(100% - 55px);
	}
	.expanded #main-content {
		max-width: calc(100% - 220px);
	}
	#main-sidebar {
		width: 55px;
		transition: 0.3s ease-in-out;
	}
	.expanded #main-sidebar {
		width: 220px;
	}
}
#mobileOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	transition: 0.3s ease-in-out;
	z-index: -1;
	background-color: rgba(0, 0, 0, 0);
	transition: 0.3s;
}
@media (max-width: 767px) {
	.expanded #mobileOverlay {
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 999;
	}
}
.main-content__inner {
	min-height: 100%;
}
.viewer-wrapper-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	min-height: 40px;
	flex-direction: column;
	margin-bottom: auto;
}
.viewer-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	margin-bottom: auto;
	height: 10px;
	background: linear-gradient(
		to top,
		var(--mdb-secondary),
		rgba(255, 165, 0, 0)
	);

	transition: all 0.3s ease-in-out;
}
.viewer-bottom-show {
	height: 60px;
	padding: 25px;
	background: linear-gradient(
		to top,
		var(--mdb-secondary),
		var(--mdb-secondary) 60%,
		rgba(255, 165, 0, 0)
	);
}
.show-footer-viewers {
	font-size: 18px;
}
</style>
