import { defineStore } from "pinia"

export const useNotificationStore = defineStore("notification_store", {
	state: () => ({
		// Define your state properties here
		unreadNotificationCount: 0,
	}),
	getters: {
		getUnreadNotificationCount: (state) => state.unreadNotificationCount,
	},
	actions: {
		/**
		 * set unread notification count
		 */
		setUnreadNotificationCount(count) {
			// console.log("setUnreadNotificationCount", count)
			this.unreadNotificationCount = count
		},

		/**
		 * increment count
		 */
		incrementUnreadNotificationCount() {
			this.unreadNotificationCount++
		},
	},
})
