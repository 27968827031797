<template>
	<button
		class="header-icon btn btn-outline-primary btn-sm btn-floating shadow-0 overflow-visible d-flex justify-content-center align-items-center border border-primary rounded-circle m-0"
		:class="menu ? 'bg-primary-3' : ''"
		@click.prevent="menu = !menu"
		@touchend.prevent="menu = !menu"
	>
		<FontAwesomeIcon
			:icon="lookupIcon('bell', 'far')"
			:class="menu ? 'text-white' : ''"
			size="lg"
		/>
		<MDBBadge
			v-if="notificationStore.getUnreadNotificationCount"
			notification
			color="danger"
			pill
		>
			{{ notificationStore.getUnreadNotificationCount }}
		</MDBBadge>
	</button>

	<MDBModal
		id="notification-modal"
		v-model="menu"
		tabindex="-1"
		class="quick-menu-modal"
		side
		position="top right"
		direction="right"
		size="sm"
	>
		<MDBModalBody class="">
			<NotificationList @close="menu = false" />
		</MDBModalBody>
	</MDBModal>
</template>

<script setup>
import { ref, onMounted, defineAsyncComponent } from "vue"
import { useToast } from "@/Composables/useToast"
import { MDBBadge, MDBModal, MDBModalBody } from "mdb-vue-ui-kit"
import NotificationList from "@/Components/NotificationList.vue"
import { useNotificationStore } from "@/Store/notificationStore"
import { usePage } from "@inertiajs/vue3"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"

const notificationStore = useNotificationStore()

const toast = useToast()
const menu = ref(false)

onMounted(() => {
	notificationStore.setUnreadNotificationCount(
		usePage().props.unreadNotificationsCount || 0,
	)
})

const userId = usePage()?.props?.auth?.user?.id || null

let notificationListener = null
if (userId) {
	notificationListener = window.Echo.private(
		"App.Models.Admin." + userId,
	).notification((notification) => {
		notificationStore.incrementUnreadNotificationCount()
		const component = notification.component
		// load the component
		if (notification.component) {
			const AsyncComp = defineAsyncComponent(() => {
				return new Promise((resolve, reject) => {
					// Example of loading a component dynamically
					import(
						`../../../Components/Notifications/${notification.component}.vue`
					)
						.then((component) => {
							resolve(component)
						})
						.catch((error) => {
							reject(error)
						})
				})
			})

			toast(
				{
					component: AsyncComp,
					props: {
						notification: notification,
					},
				},
				{
					...notification.toast_options,
				},
			)
			return
		}
		toast(notification.message, {
			...notification.toast_options,
		})
	})
}
</script>
<style scoped>
.dropend .dropdown-toggle::after {
	display: none;
}
.quick-menu-modal {
	background-color: transparent !important;
}
.badge {
	position: absolute;
	left: calc(100% - 8px);
	top: -3px;
}
</style>
