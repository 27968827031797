import route from "ziggy-js"
import {
	AGENDA,
	AGENDA_SIDEBAR,
	AI_PLATFORM,
	SOCIAL_STREAMS,
	HEADER_MENU,
	CUSTOM_DASHBOARDS,
	ATTENDEES,
	ATTENDEES_SIDEBAR,
	PORTALS,
	FORMS,
	FORMS_SIDEBAR,
	GROUPS,
	COMMUNICATION,
	COMMUNICATION_SIDEBAR,
	BADGING,
	BADGING_SIDEBAR,
	FULL_SIDE_NAVIGATION,
	IMPORTER,
	EXPORTER,
	LOGS_SIDEBAR,
} from "@/Data/dashboardMaskFeatures"
import { filter } from "lodash"
import { usePage } from "@inertiajs/vue3"

const page = usePage()

export const navItems = {
	// Nav items without permissions are at the top of this list
	// Nav items that require super admin are at the bottom
	"account-logout": {
		title: "Logout",
		icon: "sign-out",
		route: ({ props, page }) => route("dashboard.logout"),
		method: "post",
	},
	"plugin-api-docs": {
		title: "API Docs",
		route: route("docs"),
		icon: "book",
		inertia: false,
		target: "_blank",
	},
	"account-settings": {
		title: "Account Settings",
		icon: "cog",
		route: ({ props, page }) => route("dashboard.profile.show"),
	},
	"project-agenda": {
		component: "Admin/Projects/Agenda/Index",
		activeComponents: ["Admin/Projects/Agenda/Show"],
		headerGroup: "project-configure",
		icon: "calendar",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.agenda-items.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Agenda",
		features: [AGENDA],
		sidebarFeatures: [AGENDA_SIDEBAR],
	},
	"project-forms": {
		component: "Admin/Projects/Forms/Index",
		activeComponents: [
			"Admin/Projects/Forms/Show",
			"Admin/Projects/Forms/Sections",
			"Admin/Projects/Forms/Fields",
		],
		headerGroup: "project-configure",
		icon: "table-list",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.forms.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Forms",
		features: [FORMS],
		sidebarFeatures: [FORMS_SIDEBAR],
		permission: "view_project_forms",
	},
	"project-forms-show": {
		component: "Admin/Projects/Forms/Show",
		headerGroup: "project-configure",
		icon: "table-list",
		title: "Form Details",
		features: [FORMS],
		sidebarFeatures: [FORMS_SIDEBAR],
		permission: "view_project_forms",
	},
	"project-locations": {
		component: "Admin/Projects/Locations/Index",
		activeComponents: ["Admin/Projects/Locations/Show"],
		headerGroup: "manage-locations",
		icon: "map",
		features: [AGENDA],
		sidebarFeatures: [AGENDA_SIDEBAR],
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.locations.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Locations",
	},
	"project-location-show": {
		component: "Admin/Projects/Locations/Show",
		headerGroup: "manage-locations",
		icon: "map",
		title: "Locations",
		features: [AGENDA],
		sidebarFeatures: [AGENDA_SIDEBAR],
	},
	"project-credential-types": {
		component: "Admin/Projects/AttendeeCredentialTypes/Index",
		activeComponents: ["Admin/Projects/AttendeeCredentialTypes/Show"],
		headerGroup: "manage-credential-types",
		icon: "id-badge",
		features: [BADGING],
		sidebarFeatures: [BADGING_SIDEBAR],
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.credential-types.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Credential Types",
	},
	"project-access-points": {
		component: "Admin/Projects/AccessPoints/Index",
		activeComponents: ["Admin/Projects/AccessPoints/Show"],
		headerGroup: "manage-access-points",
		icon: "door-open",
		features: [BADGING],
		sidebarFeatures: [BADGING_SIDEBAR],
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.access-zones.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Access Zones",
	},
	"project-access-points-show": {
		component: "Admin/Projects/AccessPoints/Show",
		features: [BADGING],
		icon: "door-open",
		title: "Access Zones",
	},
	"project-readers": {
		component: "Admin/Projects/Readers/Index",
		activeComponents: ["Admin/Projects/Readers/Show"],
		headerGroup: "manage-access-points",
		icon: "user-magnifying-glass",
		features: [BADGING],
		sidebarFeatures: [BADGING_SIDEBAR],
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.access-zones.readers.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Readers",
	},
	"home-dashboard": {
		component: "Dashboard",
		title: "Home",
		route: ({ props, page }) =>
			route("dashboard.dashboard", {
				team: page.props.currentTeam.slug,
			}),
		icon: "house",
	},
	"home-experiences": {
		component: "HomeExperiences",
		title: "Experiences",
		icon: "folders",
		children: [
			{
				route: ({ props, page }) =>
					route("dashboard.projects.index", {
						team: page.props.currentTeam.slug,
					}),
				title: "All Experiences",
				icon: "folders",
				permission: "view_projects",
			},
			{
				title: "Recent Experiences",
				type: "recent-projects",
			},
		],
	},
	"system-experiences": {
		component: "Admin/System/Projects/Index",
		title: "Experiences",
		icon: "folders",
		route: route("system.projects.index"),
		permission: "super_admin",
	},
	"account-teams": {
		icon: "shuffle",
		title: "Team List",
		route: ({ props, page }) => route("dashboard.teams.index"),
		permission: "view_teams",
	},
	"system-teams": {
		component: "Admin/System/Teams/Index",
		activeComponents: ["Admin/System/Teams/Show"],
		icon: "users",
		title: "Teams",
		route: ({ props, page }) => route("system.teams.index"),
		permission: "super_admin",
	},
	"system-teams-show": {
		component: "Admin/System/Teams/Show",
		icon: "users",
		title: "Team Settings",
		permission: "super_admin",
	},
	"team-settings": {
		component: "Admin/Teams/Show",
		title: "Organization Settings",
		icon: "cog",
		headerGroup: "manage-team",
		route: ({ props, page }) =>
			route("dashboard.teams.show", {
				team: page.props.currentTeam.slug,
			}),
		// permission: "view_teams",
	},
	"plugin-pages": {
		component: "Admin/Plugins/Pages/Index",
		activeComponents: [
			"Admin/Plugins/Pages/Show",
			"Admin/Plugins/Pages/ShowVersion",
		],
		headerGroup: "developer-hub",
		route: ({ props, page }) =>
			route("dashboard.plugins.pages.index", {
				team: page.props.currentTeam.slug,
			}),
		title: "Apps",
		icon: "window",
		permission: "view_plugin_pages",
	},
	"plugin-pages-show": {
		component: "Admin/Plugins/Pages/Show",
		headerGroup: "developer-hub",
		title: "Apps",
		icon: "window",
		permission: "view_plugin_pages",
	},
	"plugin-pages-show-version": {
		component: "Admin/Plugins/Pages/ShowVersion",
		headerGroup: "developer-hub",
		title: "Apps",
		icon: "window",
		permission: "view_plugin_pages",
	},
	"plugin-themes": {
		component: "Admin/Plugins/Themes/Index",
		activeComponents: [
			"Admin/Plugins/Themes/Show",
			"Admin/Plugins/Themes/ShowVersion",
		],
		headerGroup: "developer-hub",
		route: ({ props, page }) =>
			route("dashboard.plugins.themes.index", {
				team: page.props.currentTeam.slug,
			}),
		title: "Themes",
		icon: "palette",
		permission: "view_plugin_themes",
	},
	"plugin-themes-show": {
		component: "Admin/Plugins/Themes/Show",
		headerGroup: "developer-hub",
		title: "Themes",
		icon: "palette",
		permission: "view_plugin_themes",
	},
	"plugin-themes-show-version": {
		component: "Admin/Plugins/Themes/ShowVersion",
		headerGroup: "developer-hub",
		title: "Themes",
		icon: "palette",
		permission: "view_plugin_themes",
	},
	"plugin-tiles": {
		component: "Admin/Plugins/Tiles/Index",
		activeComponents: [
			"Admin/Plugins/Tiles/Show",
			"Admin/Plugins/Tiles/ShowVersion",
		],
		headerGroup: "developer-hub",
		route: ({ props, page }) =>
			route("dashboard.plugins.tiles.index", {
				team: page.props.currentTeam.slug,
			}),
		title: "Tiles",
		icon: "chart-tree-map",
		permission: "view_plugin_tiles",
	},
	"plugin-tiles-show": {
		component: "Admin/Plugins/Tiles/Show",
		headerGroup: "developer-hub",
		title: "Tile",
		icon: "chart-tree-map",
		permission: "view_plugin_tiles",
	},
	"plugin-tiles-show-version": {
		component: "Admin/Plugins/Tiles/ShowVersion",
		headerGroup: "developer-hub",
		title: "Tiles",
		icon: "chart-tree-map",
		permission: "view_plugin_tiles",
	},
	"plugin-widgets": {
		component: "Admin/Plugins/Widgets/Index",
		activeComponents: [
			"Admin/Plugins/Widgets/Show",
			"Admin/Plugins/Widgets/ShowVersion",
		],
		headerGroup: "developer-hub",
		route: ({ props, page }) =>
			route("dashboard.plugins.widgets.index", {
				team: page.props.currentTeam.slug,
			}),
		title: "Widgets",
		icon: "puzzle-piece",
	},
	"plugin-widgets-show": {
		component: "Admin/Plugins/Widgets/Show",
		headerGroup: "developer-hub",
		title: "Widgets",
		icon: "puzzle-piece",
		permission: "view_plugin_widgets",
	},
	"plugin-widgets-show-version": {
		component: "Admin/Plugins/Widgets/ShowVersion",
		headerGroup: "developer-hub",
		title: "Widgets",
		icon: "puzzle-piece",
		permission: "view_plugin_widgets",
	},
	"plugin-plans": {
		component: "Admin/Plugins/Plans/Index",
		activeComponents: ["Admin/Plugins/Plans/Show"],
		title: "Plans",
		headerGroup: "developer-hub",
		route: ({ props, page }) =>
			route("dashboard.plugins.plans.index", {
				team: page.props.currentTeam.slug,
			}),
		icon: "compass-drafting",
		permission: "view_plugin_plans",
	},
	"plugin-plans-show": {
		component: "Admin/Plugins/Plans/Show",
		headerGroup: "developer-hub",
		title: "Plans",
		icon: "compass-drafting",
		permission: "view_plugin_plans",
	},
	"notification-templates-index": {
		headerGroup: "manage-notifications",
		component: "Admin/NotificationTemplates/Index",
		permission: "view_project_notification_templates",
		title: "Notification Templates",
		icon: "envelope-open-text",
		route: ({ props, page }) =>
			route("dashboard.notification-templates.index", {
				team: page.props.currentTeam.slug,
			}),
	},
	"notification-templates-show": {
		headerGroup: "manage-notifications",
		component: "Admin/NotificationTemplates/Show",
		permission: "view_project_notification_templates",
		title: "Notification Templates",
		icon: "envelope-open-text",
	},
	"ai-prompts": {
		component: "Admin/AiPrompts/Index",
		activeComponents: ["Admin/AiPrompts/Index"],
		title: "AI Prompts",
		headerGroup: "developer-hub",
		route: ({ props, page }) =>
			route("dashboard.ai-prompts.index", {
				team: page.props.currentTeam.slug,
			}),
		icon: "robot",
		permission: "view_ai_prompts",
	},
	"ai-prompts-show": {
		component: "Admin/AiPrompts/Show",
		activeComponents: ["Admin/AiPrompts/Show"],
		title: "AI Prompts",
		headerGroup: "developer-hub",
		icon: "compass-drafting",
		permission: "view_ai_prompts",
	},
	"project-assets": {
		component: "Admin/Projects/Assets/Index",
		headerGroup: "project-configure",
		icon: "images",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.assets.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Assets",
		permission: "view_project_assets",
		features: [FULL_SIDE_NAVIGATION],
	},
	"project-attendee-fields": {
		component: "Admin/Projects/AttendeeFields/Index",
		headerGroup: "configure-attendees",
		icon: "user-circle",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.attendee-fields.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Attendee Fields",
		features: [ATTENDEES],
		sidebarFeatures: [ATTENDEES_SIDEBAR],
		permission: "view_attendee_fields",
	},
	"project-attendee-list": {
		component: "Admin/Projects/Attendees/Index",
		headerGroup: "manage-attendees",
		icon: "users",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.attendees.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Attendees",
		features: [ATTENDEES],
		sidebarFeatures: [ATTENDEES_SIDEBAR],
		permission: "view_attendees",
	},
	"project-attendee-types": {
		component: "Admin/Projects/AttendeeTypes/Index",
		headerGroup: "configure-attendees",
		icon: "user-astronaut",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.attendee-types.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		features: [ATTENDEES],
		sidebarFeatures: [ATTENDEES_SIDEBAR],
		title: "Attendee Types",
		permission: "view_attendee_types",
	},
	"project-groups": {
		component: "Admin/Projects/Groups/Index",
		headerGroup: "manage-attendees",
		icon: "user-friends",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.groups.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Groups",
		features: [ATTENDEES],
		sidebarFeatures: [ATTENDEES_SIDEBAR],
		permission: "view_groups",
	},
	"project-pages": {
		component: "Admin/Projects/Pages/Index",
		activeComponents: ["Admin/Projects/Pages/Show"],
		headerGroup: "project-configure",
		icon: "rectangle-list",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.pages.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Apps",
		permission: "view_project_pages",
		features: [FULL_SIDE_NAVIGATION],
	},
	"project-pages-show": {
		component: "Admin/Projects/Pages/Show",
		headerGroup: "project-configure",
		icon: "rectangle-list",
		title: "Apps",
		permission: "view_project_pages",
		features: [FULL_SIDE_NAVIGATION],
	},
	"project-portals": {
		component: "Admin/Projects/Portals/Index",
		activeComponents: ["Admin/Projects/Portals/Show"],
		headerGroup: "project-configure",
		icon: "globe-pointer",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.portals.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Portals",
		features: [PORTALS],
		permission: "view_project_portals",
	},
	"project-portals-show": {
		component: "Admin/Projects/Portals/Show",
		headerGroup: "project-configure",
		icon: "globe-pointer",
		title: "Portals",
		permission: "view_project_portals",
	},
	"project-saved-searches": {
		component: "Admin/Projects/SavedSearches/Index",
		headerGroup: "manage-attendees",
		icon: "search",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.saved-searches.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		title: "Saved Searches",
		permission: "view_saved_searches",
	},
	"project-settings": {
		component: "Admin/Projects/Show",
		title: "Advanced Configuration",
		icon: "wrench",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.projects.show", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		permission: "view_projects",
	},
	"team-invitations": {
		component: "Admin/Teams/TeamInvitations/Index",
		headerGroup: "manage-team",
		route: ({ props, page }) =>
			route("dashboard.team-invitations.index", {
				team: page.props.currentTeam.slug,
			}),
		title: "Invites",
		permission: "view_team_members",
	},
	"team-members": {
		component: "Admin/Teams/TeamMembers/Index",
		icon: "user-group",
		headerGroup: "manage-team",
		route: ({ props, page }) =>
			route("dashboard.team-members.index", {
				team: page.props.currentTeam.slug,
			}),
		title: "Members",
		// permission: "view_team_members",
	},

	"team-projects": {
		component: "Admin/Projects/Index",
		icon: "folders",
		headerGroup: "manage-team",
		route: ({ props, page }) =>
			route("dashboard.projects.index", {
				team: page.props.currentTeam.slug,
			}),
		title: "All Experiences",
		permission: "view_projects",
	},
	"team-roles": {
		component: "Admin/Roles/Index",
		headerGroup: "manage-team",
		route: ({ props, page }) =>
			route("dashboard.roles.index", {
				team: page.props.currentTeam.slug,
			}),
		title: "Roles",
		permission: "view_roles",
	},
	"team-subscription-plans": {
		component: "Admin/SubscriptionPlans/Index",
		icon: "level-up",
		headerGroup: "manage-team",
		route: ({ props, page }) =>
			route("dashboard.subscription-plans.index", {
				team: page.props.currentTeam.slug,
			}),
		title: "Upgrade Account",
		// permission: "view_subscriptions",
	},
	"team-subscriptions": {
		component: "Admin/TeamSubscriptions/Index",
		icon: "credit-card",
		headerGroup: "manage-team",
		route: ({ props, page }) =>
			route("dashboard.subscriptions.index", {
				team: page.props.currentTeam.slug,
			}),
		title: "Subscription",
		// permission: "view_subscriptions",
	},
	"project-imports": {
		component: "Admin/Projects/Imports/Index",
		activeComponents: ["Admin/Projects/Imports/Create"],
		title: "Imports",
		icon: "file-import",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.imports.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		features: [IMPORTER],
		sidebarFeatures: [LOGS_SIDEBAR],
		permission: "view_logs",
	},
	"project-imports-create": {
		component: "Admin/Projects/Imports/Create",
		title: "Imports",
		icon: "file-import",
		permission: "view_logs",
	},
	"super-admin-imports": {
		component: "Admin/System/Imports/Index",
		title: "Imports",
		icon: "file-import",
		route: ({ props, page }) => route("system.imports.index", {}),
		permission: "super_admin",
	},
	"project-exports": {
		component: "Admin/Projects/Exports/Index",
		title: "Exports",
		icon: "file-export",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.exports.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		features: [EXPORTER],
		sidebarFeatures: [LOGS_SIDEBAR],
		permission: "view_logs",
	},
	"project-audits": {
		component: "Admin/Projects/Audits/Index",
		title: "Activity Log",
		icon: "user-pen",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.audits.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		sidebarFeatures: [LOGS_SIDEBAR],
		permission: "view_logs",
	},
	"project-social-streams": {
		component: "Admin/Projects/SocialStreams/Index",
		activeComponents: ["Admin/Projects/SocialStreams/Show"],
		title: "Social Streams",
		features: [SOCIAL_STREAMS],
		icon: "rss-square",
		route: ({ props, page }) =>
			route("dashboard.project.social-streams.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		permission: "view_social_streams",
	},
	"project-social-streams-show": {
		component: "Admin/Projects/SocialStreams/Show",
		icon: "rss-square",
		title: "Social Streams",
	},
	"project-ai-interfaces": {
		component: "Admin/Projects/AiInterfaces/Index",
		activeComponents: ["Admin/Projects/AiInterfaces/Show"],
		title: "AI Interfaces",
		features: [AI_PLATFORM],
		icon: "brain-circuit",
		route: ({ props, page }) =>
			route("dashboard.project.ai-interfaces.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		permission: "view_ai_interfaces",
	},
	"project-ai-interfaces-show": {
		component: "Admin/Projects/AiInterfaces/Show",
		icon: "brain-circuit",
		title: "AI Interfaces",
	},

	"system-modules": {
		title: "Modules",
		icon: "cube",
		children: [
			"system-social-stream-posts",
			"system-ai-messages",
			"super-admin-imports",
		],
		permission: "super_admin",
	},
	"system-subscriptions": {
		title: "Subscriptions",
		icon: "credit-card",
		children: [
			"global-system-tags",
			"global-dashboard-masks",
			"global-subscription-coupons",
		],
		permission: "super_admin",
	},
	"system-platform": {
		title: "Platform",
		icon: "server",
		children: [
			"global-announcements",
			"system-ai-models",
			"system-admin-ai-sessions",
			"email-send-configurations",
			"global-top-domains",
			"permissions",
			"sso-providers",
			"attendee-authentication-methods",
			"queue-monitor",
		],
		permission: "super_admin",
	},
	"global-dashboard-masks": {
		component: "Admin/System/DashboardMasks/Index",
		activeComponents: ["Admin/System/DashboardMasks/Show"],
		title: "Subscription Masks",
		route: route("system.dashboard-masks.index"),
		icon: "mask",
		permission: "super_admin",
	},
	"global-dashboard-masks-show": {
		component: "Admin/System/DashboardMasks/Show",
		title: "Subscription Masks",
		icon: "mask",
		permission: "super_admin",
	},
	"global-system-tags": {
		component: "Admin/System/SystemTags/Index",
		title: "System Tags",
		route: route("system.system-tags.index"),
		icon: "tag",
		permission: "super_admin",
	},
	"global-platform-stats": {
		component: "Admin/System/PlatformStats/Index",
		title: "Stats",
		route: route("system.platform-stats.index"),
		icon: "chart-simple",
		permission: "super_admin",
	},
	"global-subscription-coupons": {
		component: "Admin/System/SubscriptionCoupons/Index",
		activeComponents: ["Admin/System/SubscriptionCoupons/Show"],
		title: "Subscription Coupons",
		route: route("system.subscription-coupons.index"),
		icon: "ticket",
		permission: "super_admin",
	},
	"global-subscription-coupons-show": {
		component: "Admin/System/SubscriptionCoupons/Show",
		title: "Subscription Coupons",
		icon: "ticket",
		permission: "super_admin",
	},
	"system-ai-models": {
		component: "Admin/System/AiModels/Index",
		activeComponents: ["Admin/System/AiModels/Show"],
		title: "System AI Models",
		icon: "brain-circuit",
		permission: "super_admin",
		route: route("system.ai-models.index"),
	},
	"system-ai-models-show": {
		component: "Admin/System/AiModels/Show",
		title: "System AI Models",
		icon: "brain-circuit",
		permission: "super_admin",
	},
	"system-ai-messages": {
		component: "Admin/System/AiMessages/Index",
		title: "AI Messages",
		icon: "message-bot",
		permission: "super_admin",
		route: route("system.ai-messages.index"),
	},
	"system-admin-ai-sessions": {
		component: "Admin/System/AdminAiSessions/Index",
		title: "Admin AI Chats",
		icon: "message-bot",
		permission: "super_admin",
		route: route("system.admin-ai-sessions.index"),
	},
	"project-dashboard": {
		component: "Admin/Projects/Dashboards/Dashboard",
		activeComponents: [
			"Admin/Projects/Dashboards/Show",
			"Admin/Projects/Dashboards/Index",
		],
		title: "Dashboard",
		requiresProject: true,
		icon: "chart-line",
		route: ({ props, page }) =>
			route("dashboard.project.default-dashboard", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		permission: "view_project_dashboards",
	},
	"project-dashboard-show": {
		component: "Admin/Projects/Dashboards/Show",
		permission: "view_project_dashboards",
	},
	"project-dashboard-index": {
		component: "Admin/Projects/Dashboards/Index",
		permission: "view_project_dashboards",
	},
	"project-email-templates-index": {
		headerGroup: "manage-notifications",
		component: "Admin/Projects/EmailTemplates/Index",
		permission: "view_project_notification_templates",
		title: "Notification Templates",
		icon: "envelope-open-text",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.email-templates.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		sidebarFeatures: [COMMUNICATION_SIDEBAR],
		features: [COMMUNICATION],
	},
	"project-email-templates-show": {
		headerGroup: "manage-notifications",
		component: "Admin/Projects/EmailTemplates/Show",
		permission: "view_project_notification_templates",
		title: "Notification Templates",
		icon: "envelope-open-text",
		requiresProject: true,
		sidebarFeatures: [COMMUNICATION_SIDEBAR],
		features: [COMMUNICATION],
	},
	"project-notification-schedules-index": {
		headerGroup: "manage-notifications",
		component: "Admin/Projects/NotificationSchedules/Index",
		permission: "view_project_notification_schedules",
		title: "Notification Command Center",
		icon: "calendar-alt",
		requiresProject: true,
		route: ({ props, page }) =>
			route("dashboard.project.notification-schedules.index", {
				project: page.props.project.slug,
				team: page.props.currentTeam.slug,
			}),
		sidebarFeatures: [COMMUNICATION_SIDEBAR],
		features: [COMMUNICATION],
	},
	"project-notification-schedules-show": {
		headerGroup: "manage-notifications",
		component: "Admin/Projects/NotificationSchedules/Show",
		permission: "view_project_notification_schedules",
		title: "Notification Command Center",
		icon: "calendar-alt",
		requiresProject: true,
		features: [COMMUNICATION],
		sidebarFeatures: [COMMUNICATION_SIDEBAR],
	},

	"global-announcements": {
		component: "Admin/System/GlobalAnnouncements/Index",
		activeComponents: [
			"Admin/System/GlobalAnnouncements/Index",
			"Admin/System/GlobalAnnouncements/Show",
		],
		title: "Global Announcements",
		icon: "bullhorn",
		route: ({ props, page }) => route("system.global-announcements.index"),
		permission: "super_admin",
	},
	admins: {
		component: "Admin/System/Admins/Index",
		activeComponents: ["Admin/System/Admins/Show"],
		title: "Admins",
		icon: "user-tie",
		route: ({ props, page }) => route("system.admins.index"),
		permission: "super_admin",
	},
	"admins-show": {
		component: "Admin/System/Admins/Show",
		icon: "user-tie",
		title: "Admins",
		permission: "super_admin",
	},
	"queue-monitor": {
		title: "Jobs Monitor",
		route: "/horizon",
		icon: "briefcase",
		inertia: false,
		target: "_blank",
	},
	"global-top-domains": {
		component: "Admin/System/TopDomains/Index",
		activeComponents: ["Admin/System/TopDomains/Show"],
		title: "Domains",
		route: route("system.top-domains.index"),
		icon: "globe",
		permission: "super_admin",
	},
	"global-top-domains-show": {
		component: "Admin/System/TopDomains/Show",
		title: "Domains",
		icon: "globe",
		permission: "super_admin",
	},
	"global-email-logs": {
		component: "Admin/System/EmailLogs/Index",
		title: "Email Logs",
		route: route("system.email-logs.index"),
		icon: "notebook",
		permission: "super_admin",
	},
	"global-sms-message-logs": {
		component: "Admin/System/SmsMessageLogs/Index",
		title: "SMS Message Logs",
		route: route("system.sms-message-logs.index"),
		icon: "sms",
		permission: "super_admin",
	},
	"email-send-configurations": {
		component: "Admin/System/EmailSendConfigurations/Index",
		title: "Send From Email Configurations",
		route: route("system.email-send-configurations.index"),
		icon: "envelope",
		permission: "super_admin",
	},
	permissions: {
		component: "Admin/System/Permissions/Index",
		activeComponents: ["Admin/System/Permissions/Show"],
		route: ({ props, page }) => route("system.permissions.index", {}),
		title: "Permissions",
		icon: "user-shield",
		permission: "super_admin",
	},
	"permissions-show": {
		component: "Admin/System/Permissions/Show",
		title: "Permissions",
		icon: "user-shield",
		permission: "super_admin",
	},
	"system-social-stream-posts": {
		component: "Admin/System/SocialStreamPosts/Index",
		route: () => route("system.social-stream-posts.index"),
		title: "Social Stream Posts",
		icon: "rss-square",
		permission: "super_admin",
	},
	"sso-providers": {
		component: "Admin/SSO/Index",
		route: ({ props, page }) => route("dashboard.sso-providers.index", {}),
		title: "SSO Providers",
		icon: "user-shield",
		permission: "super_admin",
	},
	"attendee-authentication-methods": {
		component: "Admin/AttendeeAuthenticationMethods/Index",
		route: ({ props, page }) =>
			route("system.attendee-authentication-methods.index", {}),
		title: "Attendee Authentication Methods",
		icon: "user-shield",
		permission: "super_admin",
	},
}
const headerGroups = {
	"developer-hub": [
		"plugin-pages",
		"plugin-themes",
		"plugin-tiles",
		"plugin-widgets",
		"plugin-plans",
	],
	"project-configure": [
		"project-pages",
		"project-portals",
		"project-forms",
		"project-assets",
	],
	"manage-team": [
		"team-settings",
		"team-projects",
		"team-members",
		"team-invitations",
		"team-roles",
		"team-subscriptions",
		"team-subscription-plans",
	],
	"manage-locations": ["project-locations"],
	"manage-access-points": ["project-access-points", "projects-readers"],
	"manage-attendees": [
		"project-attendee-list",
		"project-groups",
		"project-saved-searches",
	],
	"manage-notifications": [
		"project-notification-schedules-index",
		"project-email-templates-index",
	],
	"configure-attendees": ["project-attendee-types", "project-attendee-fields"],
}
let componentLookup = {}
Object.keys(navItems).forEach((key) => {
	let item = navItems[key]
	componentLookup[item.component] = key
})

const navLists = {
	"team-nav": ["team-projects", "team-members", "team-subscriptions"],
	"account-nav": ["account-settings", "account-teams", "account-logout"],
	"system-nav": [
		"global-platform-stats",
		"system-teams",
		"admins",
		"system-modules",
		"system-subscriptions",
		"system-platform",
	],
	"project-nav": [
		"project-dashboard",
		{
			title: "Apps",
			icon: "grid",
			requiresProject: true,
			children: [
				"project-pages",
				"project-portals",
				"project-forms",
				"project-assets",
			],
		},
		{
			title: "Attendees",
			icon: "users",
			permission: "view_attendees",
			requiresProject: true,
			children: [
				"project-attendee-list",
				"project-attendee-types",
				"project-attendee-fields",
				"project-groups",
			],
		},
		{
			title: "Agenda",
			icon: "calendar",
			requiresProject: true,
			children: ["project-agenda", "project-locations"],
		},
		{
			title: "Badging",
			icon: "id-card",
			requiresProject: true,
			children: [
				"project-credential-types",
				"project-access-points",
				"project-readers",
			],
		},
		{
			title: "Communications",
			icon: "envelope-open-text",
			requiresProject: true,
			children: [
				"project-notification-schedules-index",
				"project-email-templates-index",
			],
		},
		{
			title: "Data",
			icon: "clipboard-list",
			requiresProject: true,
			children: ["project-imports", "project-exports", "project-audits"],
		},
		{
			title: "Streaming",
			icon: "video",
			requiresProject: true,
			features: [FULL_SIDE_NAVIGATION],
			children: [],
		},
		{
			title: "Services",
			icon: "gauge-circle-plus",
			requiresProject: true,
			features: [FULL_SIDE_NAVIGATION],
			children: ["project-social-streams", "project-ai-interfaces"],
		},
		"project-settings",
	],
	"lower-nav": [
		{
			title: "Developer",
			icon: "code",
			children: [
				"plugin-pages",
				"plugin-tiles",
				"plugin-themes",
				"plugin-widgets",
				"plugin-plans",
				"ai-prompts",
				"notification-templates-index",
				"plugin-api-docs",
			],
			permission: "super_admin",
		},
		{
			title: "Platform Admin",
			icon: "user-secret",
			item: "global-platform-stats",
			permission: "super_admin",
		},
	],
	organizations: [
		{
			title: "View All Organizations",
			icon: "network-wired",
			item: "account-teams",
		},
		{
			title: "Organization Settings",
			icon: "cog",
			item: "team-settings",
		},
		{
			title: "Members",
			icon: "user-group",
			item: "team-members",
		},
		{
			title: "Billing & Subscriptions",
			icon: "credit-card",
			// permission: "manage_subscriptions",
			item: "team-subscriptions",
		},
	],
	user: [
		{
			title: "Account Settings",
			icon: "cog",
			item: "account-settings",
		},
		{
			title: "Logout",
			icon: "right-from-bracket",
			item: "account-logout",
		},
	],
	global: ["home-dashboard", "home-experiences"],
	"global-system": ["home-dashboard", "system-experiences"],
}

export function getNavComponent(component) {
	const navKey = componentLookup[component]
	return navItems[navKey]
}
export function getHeaderItems(component, project, features, permissions) {
	if (component && componentLookup[component]) {
		const itemIndex = componentLookup[component]
		let navComponent = navItems[itemIndex]
		if (
			navComponent["headerGroup"] === "developer-hub" ||
			(features &&
				features.includes(HEADER_MENU) &&
				navComponent["headerGroup"])
		) {
			return getNavItems(
				navComponent["headerGroup"],
				project,
				features,
				permissions,
				true,
			)
		}
	}
	return []
}
export function getNavItems(
	listKey,
	project,
	features,
	permissions,
	headerGroup = false,
) {
	const featureSet = new Set(features)
	function checkItem(item) {
		permissions = permissions ?? {}

		if (typeof item === "string") {
			item = navItems[item]
		}

		const itemIsAnArray = Array.isArray(project)
		const projectCheck = item.requiresProject
			? project != null && !itemIsAnArray
			: true

		let featureCheck = false // disallow is default
		if (!item.features) {
			featureCheck = true // we aren't checking for features on this nav item, allow
		} else if (item.features && item.sidebarFeatures) {
			if (item.features && featureSet.has(FULL_SIDE_NAVIGATION)) {
				// regular feature check since full side is enabled
				featureCheck = item.features.some((tag) => featureSet.has(tag))
			} else {
				// full side isnt enabled, check if sidebar feature is included
				featureCheck =
					item.sidebarFeatures.some((tag) => featureSet.has(tag)) &&
					item.features.some((tag) => featureSet.has(tag))
			}
		} else if (item.features && !featureSet.has(FULL_SIDE_NAVIGATION)) {
			featureCheck = false
		} else if (item.features) {
			featureCheck = item.features.some((tag) => featureSet.has(tag))
		}

		const permCheck = item.permission
			? permissions[item.permission] || permissions["super_admin"]
			: true
		return projectCheck && featureCheck && permCheck
	}
	let listItems = {}
	if (headerGroup) {
		listItems = headerGroups
	} else {
		listItems = navLists
	}

	if (listKey === "global" && page.props.routeList === "system") {
		listKey = "global-system"
	}

	const filteredItems = listItems[listKey]
		? listItems[listKey]
				.map((item) => {
					if (typeof item === "string") {
						item = navItems[item]
					}
					if (item && item.item) {
						item = { ...navItems[item.item], ...item }
					}
					if (item && checkItem(item) && item.children) {
						const clonedItem = { ...item } // Clone the item
						clonedItem.children = item.children
							.map((child) => {
								if (typeof child === "string") {
									child = navItems[child]
								}
								return checkItem(child) ? child : null // If child doesn't pass the check, return null
							})
							.filter(Boolean) // Remove null children
						return clonedItem.children.length === 0 ? null : clonedItem // If no children are left, return null
					}
					return item && checkItem(item) ? item : null // If item itself doesn't pass the check, return null
				})
				.filter(Boolean)
		: [] // Remove null items
	if (
		listKey === "project-nav" &&
		project &&
		project.top_shortcuts &&
		project.top_shortcuts.length > 0
	) {
		// Add top shortcuts to the beginning of the list after dashboard
		let parsedItems = [filteredItems.shift()]
		let topShortcuts = project.top_shortcuts.map((item) => {
			return {
				title: item.label,
				icon: item.icon,
				classList: item.classList || [],
				href: item.navigation_path,
			}
		})
		parsedItems = parsedItems.concat(topShortcuts)
		parsedItems = parsedItems.concat(filteredItems)

		return parsedItems
	} else {
		return filteredItems
	}
}
