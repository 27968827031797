<template>
	<MDBTooltip
		v-model="tooltip"
		offset="0,5"
		direction="right"
		:hidden="isExistingTopLevelShortcut"
	>
		<template #reference>
			<a
				class="icon-link text-primary-3 btn-outline-primary rounded-pill smaller py-1 px-2 fw-bold d-none d-md-inline-block"
				href="#"
				:class="{
					bookmarked: isSelected,
					'bg-primary-9': isSelected,
					'btn-outline-primary': !isSelected,
				}"
				@click="toggleSelection"
			>
				{{ bookmarkText }}
			</a>
			<a
				class="d-inline-block d-md-none btn btn-outline-primary btn-md btn-floating shadow-0 overflow-visible d-flex justify-content-center align-items-center border border-primary rounded-circle m-0"
				href="#"
				@click="toggleSelection"
			>
				<FontAwesomeIcon
					v-if="isSelected"
					:icon="lookupIcon('bookmark', 'fas')"
					class="text-primary"
				/>
				<FontAwesomeIcon
					v-else
					:icon="lookupIcon('bookmark', 'far')"
					class="text-primary"
				/>
			</a>
		</template>
		<template #tip> Toggle Bookmark </template>
	</MDBTooltip>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue"
import { router, usePage } from "@inertiajs/vue3"
import { MDBTooltip } from "mdb-vue-ui-kit"
import { useProjectStore } from "@/Store/projectStore"
import route from "ziggy-js"
import { useToast } from "@/Composables/useToast"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
const toast = useToast()

const props = defineProps({
	urlString: {
		type: String,
		required: true,
	},
	icon: {
		type: String,
		default: "poo",
	},
	label: {
		type: String,
		default: "Shortcut",
	},
	color: {
		type: String,
		default: "#ffffff",
	},
})

const isSelected = ref(false)
const projectStore = useProjectStore()
const tooltip = ref(false)

const isExistingTopLevelShortcut = computed(() => {
	if (!isSelected.value) return false
	return projectStore.getActiveProject.shortcuts.some(
		(shortcut) =>
			shortcut.is_top_level && shortcut.navigation_path === props.urlString,
	)
})

const checkSelection = async () => {
	const currentUrls = computed(() => {
		if (!projectStore.getActiveProject?.shortcuts) {
			return []
		}
		return projectStore.getActiveProject.shortcuts.map((shortcut) => {
			return shortcut.navigation_path
		})
	})

	isSelected.value = currentUrls.value.includes(props.urlString)
}

const toggleSelection = async () => {
	let toggleData = {
		navigation_path: props.urlString,
		label: props.label,
		icon: props.icon,
		color: props.color,
	}
	if (isSelected.value) {
		// Already selected, send POST request to deactivate
		toggleData["is_active"] = false
	} else {
		// Not selected, send PUT request to activate
		toggleData["is_active"] = true
	}
	router.post(
		route("dashboard.project.navigation-shortcuts.toggle", {
			project: projectStore.getActiveProject.slug,
			team: usePage().props.currentTeam.slug,
		}),
		toggleData,
		{
			onSuccess: (event) => {
				toast.success("Successfully updated data")
			},
			onError: (errors) => {
				toast.error(Object.values(errors).join("\n"))
			},
		},
	)
	isSelected.value = !isSelected.value
}

const bookmarkText = computed(() =>
	isSelected.value ? "Bookmarked" : "Bookmark",
)

onMounted(checkSelection)
watch(() => props.urlString, checkSelection)
</script>

<style scoped>
.icon-link {
	min-width: 100px;
	display: inline-block;
	text-align: center;
}
.bookmarked:hover {
	position: relative;
}
.bookmarked:hover::after {
	content: "Remove";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 500px;
}
.bookmarked:active::after {
	color: #000596;
}
</style>
